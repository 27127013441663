// StorePreview helper functions and data
import { sortSchedule } from "@sushicorp/utils";
import { Schedule } from "artisn/types";
import dayjs from "dayjs";

/**
 * Helper to get store opening hour
 *
 * @since 1.0.0
 * @param {Schedule[]} schedules Store schedule.
 * @param {number} catalogueId Catalogue id.
 * @returns A string of the next opening hour of the store.
 */
export const nextOpeningHourHelper = (
  schedules: Schedule[],
  catalogueId: number
) => {
  if (!schedules) return "";

  const now = dayjs();
  const today = dayjs().get("day");

  const sortedByDay = sortSchedule(schedules, catalogueId.toString());

  const scheduleStart = sortedByDay.slice(today);
  const scheduleEnd = sortedByDay.slice(0, today);
  const sortedSchedules = [...scheduleStart, ...scheduleEnd];

  const selectedSchedule = sortedSchedules.find(schedule => {
    return now.isBefore(dayjs().add(schedule.from, "seconds"));
  });

  if (!selectedSchedule) return "";

  const timeString = dayjs()
    .startOf("day")
    .add(selectedSchedule.from, "seconds")
    .format("HH:mm a");

  return `${timeString}`;
};

/**
 * Helper to get store closing hour
 *
 * @since 1.0.0
 * @param {Schedule[]} schedules Store schedule.
 * @param {number} catalogueId Catalogue id.
 * @returns A string of the next opening hour of the store.
 */
export const nextClosingHourHelper = (
  schedules: Schedule[],
  catalogueId: number
) => {
  if (!schedules) return "";

  const now = dayjs();
  const today = dayjs().get("day");

  const sortedByDay = sortSchedule(schedules, catalogueId.toString());

  const scheduleStart = sortedByDay.slice(today);
  const scheduleEnd = sortedByDay.slice(0, today);
  const sortedSchedules = [...scheduleStart, ...scheduleEnd];

  const selectedSchedule = sortedSchedules.find(schedule => {
    return now.isBefore(dayjs().add(schedule.to, "seconds"));
  });

  if (!selectedSchedule) return "";

  const timeString = dayjs()
    .startOf("day")
    .add(selectedSchedule.to, "seconds")
    .format("HH:mm a");

  return `${timeString}`;
};
