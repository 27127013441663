import { Modal } from "artisn-ui-react";
import styled from "styled-components";

const ErrorStyled = styled(Modal)`
  .Modal__body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.2rem 0;
  }

  .Error {
    &__text {
      color: var(--palette-black-s0-l10);
      font-size: 2rem;
      line-height: 2.8rem;
      padding-top: 1.2rem;
      text-align: center;

      &--title {
        font-weight: 700;
      }

      &--description {
        font-weight: 400;
      }
    }

    &__button {
      margin-top: 2.4rem;
      border-radius: 2.4rem;
      font-size: 2rem;
    }
  }
`;

export default ErrorStyled;
