import { buildArtisnHeaders } from "@sushicorp/services";
import { applyBenefit } from "artisn/shopping-cart";

import { ApplyBenefitConfig } from "./useBenefit.types";
import CONSTANTS from "config/constants";
import { validateApplyBenefitUtility } from "utils/shoppingCart.utils";

const { ARTISN, API } = CONSTANTS;
const { ACCOUNT_ID } = ARTISN;
const { API_URL } = API;

export const applyBenefitHandler = async (config: ApplyBenefitConfig) => {
  const { benefit, isAnonymous, customerId, onFinish } = config;
  const { shoppingCart, shippingCost = null, location } = config;
  const { product, store } = config;

  try {
    if (!store) return new Error("No store selected");
    const headers = await buildArtisnHeaders();
    const { benefitId, type } = benefit;
    // In the Artisn SDK, the api/shopping-cart/applyBenefit endpoint is used to apply benefits of type DISCOUNT. While for benefits of type ALTER_DELIVERY no endpoint is used.
    const isProductBenefit = type === "PRODUCT";
    if (!isProductBenefit) headers.delete("Content-Type");
    const { id } = shoppingCart ?? {};
    const { coordinates } = location ?? {};
    const { lat: latitude, lng: longitude } = coordinates ?? {};

    const isValidBenefitToApply = await validateApplyBenefitUtility(
      benefit,
      isAnonymous,
      customerId
    );
    if (!isValidBenefitToApply) throw new Error("El cupón no es válido");

    const commonBenefitConfig = {
      accountId: ACCOUNT_ID,
      anonymous: isAnonymous,
      apiURL: API_URL,
      benefitId,
      customerId,
      shoppingCartId: id,
      shippingCost
    };

    const benefitConfig = { ...commonBenefitConfig, latitude, longitude };

    const productBenefitConfig = {
      ...commonBenefitConfig,
      product,
      productConfig: {
        amount: 1,
        store,
        customerId,
        accountId: ACCOUNT_ID,
        anonymous: isAnonymous
      }
    };

    const config = !isProductBenefit ? benefitConfig : productBenefitConfig;
    await applyBenefit(config, headers);
    onFinish?.();
  } catch (e) {
    console.log({ e });

    if (e.message.includes("DISCOUNT_FIXED")) {
      throw new Error(
        "No se ha podido aplicar el cupón porque el descuento es mayor al subtotal del carrito."
      );
    }

    throw new Error("No se ha podido aplicar/remover el cupón.");
  }
};
