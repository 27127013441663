import React from "react";

import Styles from "./SearchBarProductsDrawer.styles";
import { SearchBarProductsDrawerProps as Props } from "./SearchBarProductsDrawer.types";
import SearchBarProducts from "components/global/SearchBarProducts/SearchBarProducts";

const SearchBarProductsDrawer: React.FC<Props> = props => {
  const { opened, onClose = () => {} } = props;

  return (
    <Styles
      className="SearchBarProductsDrawer"
      opened={opened}
      backdropConfig={{ className: "SearchBarProductsDrawerBackdrop" }}
      onClose={onClose}
    >
      <SearchBarProducts isDrawer />
    </Styles>
  );
};

SearchBarProductsDrawer.defaultProps = {};

export default SearchBarProductsDrawer;
