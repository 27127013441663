// Auth context
import { updateUser } from "@sushicorp/services";
import { getFirebaseAuthErrorMessage } from "@sushicorp/utils";
import { UserInfo, User, GoogleAuthProvider } from "firebase/auth";
import { signInWithPopup, signInWithCredential } from "firebase/auth";
import { FacebookAuthProvider, unlink } from "firebase/auth";
import { signInAnonymously as signInAnonymouslyFirebase } from "firebase/auth";
import { EmailAuthProvider, linkWithCredential } from "firebase/auth";
import { signInWithEmailLink as signInWithEmailLinkFirebase } from "firebase/auth";
import { isSignInWithEmailLink as isSignInWithEmailLinkFirebase } from "firebase/auth";
import { sendSignInLinkToEmail as sendSignInLinkToEmailFirebase } from "firebase/auth";
import { signInWithEmailAndPassword as signInWithEmailAndPasswordFirebase } from "firebase/auth";
import { sendPasswordResetEmail as sendPasswordResetEmailFirebase } from "firebase/auth";
import React, { createContext, FC, useCallback, useMemo } from "react";
import { useEffect, useState } from "react";

import { AuthProviderValue } from "./auth.context.types";
import { AuthProviderProps as Props } from "./auth.context.types";
import { getAuth } from "config/artisn.config";
import useI18n from "hooks/useI18n";

// @ts-ignore
export const AuthContext = createContext<AuthProviderValue>({});

const AuthProvider: FC<Props> = props => {
  const [isAnonymous, setIsAnonymous] = useState<boolean>();
  const [uid, setUid] = useState("");
  const [providerData, setProviderData] = useState<(UserInfo | null)[]>([]);
  const t = useI18n().errors.firebase.auth;

  const signInAnonymously = async () => {
    return await signInAnonymouslyFirebase(getAuth);
  };

  const subscriber = useCallback((user: User | null) => {
    updateUser(user);
    const { uid: userId, isAnonymous: isUserAnonymous } = user ?? {};
    const { providerData: provider } = user ?? {};
    setIsAnonymous(!!isUserAnonymous);
    if (provider) {
      setProviderData(provider);
    }
    if (userId) {
      setUid(userId);
      return;
    }
    signInAnonymously();
  }, []);

  useEffect(() => {
    const authSubscriber = getAuth.onAuthStateChanged(subscriber);

    return authSubscriber;
  }, [subscriber]);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    return await signInWithEmailAndPasswordFirebase(getAuth, email, password);
  };

  const sendPasswordResetEmail = async (email: string) => {
    return await sendPasswordResetEmailFirebase(getAuth, email);
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const response = await signInWithPopup(getAuth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(response);
    if (!credential || getAuth.currentUser?.isAnonymous) return response;
    await signInWithCredential(getAuth, credential);
    return response;
  };

  const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    const response = await signInWithPopup(getAuth, provider);
    const credential = FacebookAuthProvider.credentialFromResult(response);
    if (!credential || getAuth.currentUser?.isAnonymous) return response;
    await signInWithCredential(getAuth, credential);
    return response;
  };

  const registerWithEmailAndPassword = useCallback(
    async (email: string, password: string) => {
      const credential = EmailAuthProvider.credential(email, password);
      if (!getAuth?.currentUser) return;
      const response = await linkWithCredential(
        getAuth.currentUser,
        credential
      );
      if (response) subscriber(response.user);
      return response;
    },
    [subscriber]
  );

  const signInWithEmailLink = useCallback(
    async (email: string, emailLink: string) => {
      try {
        return await signInWithEmailLinkFirebase(getAuth, email, emailLink);
      } catch (error) {
        throw new Error(getFirebaseAuthErrorMessage(t, error));
      }
    },
    [t]
  );

  const isSignInWithEmailLink = useCallback(
    (emailLink: string) => {
      try {
        return isSignInWithEmailLinkFirebase(getAuth, emailLink);
      } catch (error) {
        throw new Error(getFirebaseAuthErrorMessage(t, error));
      }
    },
    [t]
  );

  const sendSignInLinkToEmail = useCallback(
    async (email: string) => {
      const url = process.env.NEXT_PUBLIC_MAGIC_LINK;
      if (!url) throw new Error("Missing resolve url");
      try {
        return await sendSignInLinkToEmailFirebase(getAuth, email, {
          url,
          handleCodeInApp: true
        });
      } catch (error) {
        throw new Error(getFirebaseAuthErrorMessage(t, error));
      }
    },
    [t]
  );

  const unlinkGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    if (!getAuth?.currentUser) return;
    const user = await unlink(getAuth.currentUser, provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const unlinkFacebook = useCallback(async () => {
    const provider = new FacebookAuthProvider();
    if (!getAuth?.currentUser) return;
    const user = await unlink(getAuth.currentUser, provider.providerId);
    if (user) subscriber(user);
  }, [subscriber]);

  const resetAuthContext = useCallback(() => {
    setIsAnonymous(false);
  }, []);

  const getEmail = () => {
    return getAuth?.currentUser?.email;
  };

  const value: AuthProviderValue = useMemo(() => {
    return {
      isAnonymous,
      signInWithEmailAndPassword,
      signInWithGoogle,
      signInWithFacebook,
      signInAnonymously,
      sendPasswordResetEmail,
      registerWithEmailAndPassword,
      sendSignInLinkToEmail,
      signInWithEmailLink,
      isSignInWithEmailLink,
      uid,
      email: getEmail(),
      providerData,
      setProviderData,
      unlinkGoogle,
      unlinkFacebook,
      resetAuthContext
    };
  }, [
    isAnonymous,
    isSignInWithEmailLink,
    providerData,
    registerWithEmailAndPassword,
    resetAuthContext,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    uid,
    unlinkFacebook,
    unlinkGoogle
  ]);

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
