import React from "react";

import Styles from "./ListItem.styles";
import { ListItemProps as Props } from "./ListItem.types";
import DropdownOptions from "../DropdownOptions/DropdownOptions";
import useI18n from "hooks/useI18n";

import HomeSVG from "../../../../public/assets/images/home.svg";
import LoadingSVG from "../../../../public/assets/images/loading.svg";
import WorkSVG from "../../../../public/assets/images/work.svg";

const ListItem: React.FC<Props> = props => {
  const t = useI18n();
  const { className, onClick, isLoading, title, description } = props;
  const { isDefault, icon: iconFromProps, isActive = false, actions } = props;
  const { isFixed, onEdit, onDelete, onDefault } = props;
  const classNameActive = isActive ? "ListItem--active" : "";
  const classNameActiveIcon = isActive ? "ListItem__icon--active" : "";

  const renderIcon = () => {
    if (isLoading) {
      return (
        <div className="ChooseAddress__loading">
          <LoadingSVG viewBox="0 0 66 66" />
        </div>
      );
    }
    const enhancedNickname = title.toUpperCase();

    let icon: JSX.Element | null = null;

    if (enhancedNickname === "CASA" || enhancedNickname === "HOME") {
      icon = <HomeSVG />;
    }
    if (enhancedNickname === "TRABAJO" || enhancedNickname === "WORK") {
      icon = <WorkSVG />;
    }

    return (
      <div className={`ListItem__icon ${classNameActiveIcon}`}>
        {icon ?? iconFromProps}
      </div>
    );
  };

  return (
    <Styles
      className={`ListItem ${classNameActive} ${className}`}
      isActive={isActive}
      isLoading={isLoading}
      clickable={!!onClick}
    >
      {renderIcon()}
      <div className="ListItem__content" onClick={onClick}>
        <div className="ListItem__title">
          <p className="ListItem__value">{title}</p>
          <span className="ListItem__default">
            {isDefault ? `(${t.profile.address.predetermined})` : ""}
          </span>
        </div>
        <div className="ListItem__description">{description}</div>
      </div>
      {actions?.length ? (
        <DropdownOptions
          onEdit={actions?.includes("edit") ? onEdit : undefined}
          onDelete={actions?.includes("delete") ? onDelete : undefined}
          onDefault={
            actions?.includes("default") && !isDefault ? onDefault : undefined
          }
          isFixed={isFixed}
        />
      ) : null}
    </Styles>
  );
};

ListItem.defaultProps = {
  className: ""
};

export default ListItem;
