import { useProducts } from "@sushicorp/contexts";
import { useFetchUser } from "@sushicorp/services";
import { getProductPreferences } from "@sushicorp/utils";
import React, { useEffect, useState } from "react";

import Styles from "./ModifiersForm.styles";
import { ModifiersFormProps as Props } from "./ModifiersForm.types";
import ModifiersGroup from "../ModifiersGroup/ModifiersGroup";
import useAuth from "contexts/auth/auth.context.hooks";
import { ProductPreferences } from "types/common.types";
import { notify } from "utils/common.utils";

const ModifiersForm: React.FC<Props> = props => {
  const { form, disabled } = props;
  const { renderer, product } = form;
  const { setSelectedProductForm } = useProducts();
  const auth = useAuth();
  const [preferences, setPreferences] = useState<ProductPreferences>();
  const { data: user } = useFetchUser(auth, notify);
  const { uid: userUid } = user ?? {};

  useEffect(() => {
    (async () => {
      const productPreferences = await getProductPreferences(product, userUid);
      setPreferences(productPreferences);
    })();
  }, [product, userUid]);

  useEffect(() => {
    if (!form) return;
    setSelectedProductForm(form);
  }, [form, setSelectedProductForm]);

  if (!preferences) return null;

  return (
    <Styles className="ModifiersForm">
      {renderer.map(group => {
        return (
          <ModifiersGroup
            preferences={preferences}
            key={group.id}
            modifierGroup={group}
            disabled={disabled}
          />
        );
      })}
    </Styles>
  );
};

ModifiersForm.defaultProps = {};

export default ModifiersForm;
