import { useCountries } from "@sushicorp/contexts";
import { Clickable } from "artisn-ui-react";
import dayjs from "dayjs";
import React from "react";

import Styles from "./CouponDetail.styles";
import { CouponDetailProps as Props } from "./CouponDetail.types";
import useI18n from "hooks/useI18n";

import CrossSmallSVG from "../../../../public/assets/images/cross-small.svg";

const CouponDetail: React.FC<Props> = props => {
  const { benefit, selected = false, inCart, className, removeError } = props;
  const { showRemove = true, onRemove, showRadio = true } = props;
  const { title, description, expirationDate } = benefit ?? {};
  const { selectedCountry } = useCountries();
  const t = useI18n();
  const { locale } = selectedCountry;
  const expired = dayjs(expirationDate)
    .locale(locale)
    .format("DD [de] MMMM, YYYY");

  return (
    <>
      <Styles className={`CouponDetail ${className}`} selected={selected}>
        <div className="CouponDetail__container">
          <div className="CouponDetail__left">
            <div className="CouponDetail__left__label">
              <p className="CouponDetail__left__label__title">{title}</p>
              <p className="CouponDetail__left__label__description">
                {description}
              </p>
              <p className="CouponDetail__left__label__expiration-date">{`${t.coupons.validUntil}: ${expired}`}</p>
            </div>
          </div>
          {showRadio && inCart ? (
            <div
              className={`CouponDetail__radio ${
                selected ? "CouponDetail__radio--active" : ""
              }`}
            />
          ) : null}
          {inCart && showRemove ? (
            <div className="CouponDetail__right">
              <Clickable
                className="CouponDetail__remove"
                onClick={() => onRemove?.()}
              >
                <CrossSmallSVG />
              </Clickable>
            </div>
          ) : null}
        </div>
        {removeError ? (
          <p className="CouponDetail__remove-error">{removeError}</p>
        ) : null}
      </Styles>
    </>
  );
};

CouponDetail.defaultProps = {
  className: ""
};

export default CouponDetail;
