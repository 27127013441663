// Initialize live orders
import { useCountries, useVendors } from "@sushicorp/contexts";
import { getFetchOrderHistoryKeys } from "@sushicorp/services";
import { getAuth } from "firebase/auth";
import { onValue, ref } from "firebase/database";
import { useEffect, useRef } from "react";
import { useQueryClient } from "react-query";

import { realtimeDb } from "config/artisn.config";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useOrders from "contexts/orders/orders.context.hooks";
import { LiveOrders } from "contexts/orders/orders.context.types";
import { OrderInProgress } from "contexts/orders/orders.context.types";

const { ARTISN } = CONSTANTS;
const { ACCOUNT_ID } = ARTISN;

const useSetupLiveOrders = () => {
  const customerId = getAuth()?.currentUser?.uid;
  const auth = useAuth();
  const { setLiveOrders } = useOrders();
  const { selectedCountry } = useCountries();
  const { selectedVendorId: vendorId } = useVendors();
  const queryClient = useQueryClient();
  const lastNumberOfOrdersInProgress = useRef<number>();

  useEffect(() => {
    if (!customerId || !selectedCountry || !vendorId) {
      return;
    }

    const ordersRef = ref(
      realtimeDb,
      `ordersByUser/${customerId}/${ACCOUNT_ID}/${vendorId}`
    );

    const unsubscribe = onValue(ordersRef, async snapshot => {
      const liveOrders = snapshot.val() as LiveOrders;
      if (liveOrders) {
        setLiveOrders(liveOrders);
        const ordersInProgress: OrderInProgress[] = [];

        Object.entries(liveOrders).forEach(([orderId, orderValue]) => {
          if (orderId === "totalOrders") return;
          Object.values(orderValue).forEach(orderStep => {
            if (!orderStep || orderStep.category !== "IN_PROGRESS") return;
            ordersInProgress.push({
              orderId: `${orderId}`,
              viewed: false
            });
          });
        });

        if (!lastNumberOfOrdersInProgress.current) {
          lastNumberOfOrdersInProgress.current = ordersInProgress.length;
          return;
        }

        if (lastNumberOfOrdersInProgress.current === ordersInProgress.length) {
          return;
        }

        lastNumberOfOrdersInProgress.current = ordersInProgress.length;
        queryClient.invalidateQueries(
          getFetchOrderHistoryKeys({ auth, category: "IN_PROGRESS", vendorId })
        );
      }
    });

    // Stop listening for updates when no longer required
    return unsubscribe;
  }, [auth, customerId, queryClient, selectedCountry, setLiveOrders, vendorId]);
};

export default useSetupLiveOrders;
