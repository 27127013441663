import React from "react";

import { ProductCardPlaceholderStyled as Styles } from "./ProductCard.styles";
import { ProductCardPlaceholderProps as Props } from "./ProductCard.types";

const ProductCardPlaceholder: React.FC<Props> = props => {
  return (
    <Styles className="ProductCardPlaceholder">
      <div className="ProductCardPlaceholder__image">
        <div className="ProductCardPlaceholder__image__shine" />
      </div>
      <div className="ProductCardPlaceholder__price">
        <div className="ProductCardPlaceholder__price__shine" />
      </div>
      <div className="ProductCardPlaceholder__name">
        <div className="ProductCardPlaceholder__name__shine" />
      </div>
      <div className="ProductCardPlaceholder__add">
        <div className="ProductCardPlaceholder__add__shine" />
      </div>
    </Styles>
  );
};

ProductCardPlaceholder.defaultProps = {};

export default ProductCardPlaceholder;
