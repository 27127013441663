import { useProducts } from "@sushicorp/contexts";
import { getMostChosenOptionKey } from "@sushicorp/utils";
import { Button } from "artisn-ui-react";
import React, { MouseEvent } from "react";

import Styles from "./RadioButtonModifier.styles";
import { RadioButtonModifierProps as Props } from "./RadioButtonModifier.types";
import ModifierPrices from "../ModifierPrices/ModifierPrices";
import ThirdLevelModifiers from "../ThirdLevelModifiers/ThirdLevelModifiers";
import useOnMount from "hooks/useOnMount";

const RadioButtonModifier: React.FC<Props> = props => {
  const { modifier, disabled = false, preferences } = props;
  const { amount, name, totals, id } = modifier;
  const { handleChange, groupId, groups } = modifier;
  const { selectedProduct } = useProducts();

  const iconActiveClassName = amount ? "RadioButtonModifier__icon--active" : "";
  const textActiveClassName = amount ? "RadioButtonModifier__text--active" : "";

  const onClickHandle = (event: MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    if (amount) {
      handleChange(0);
      return;
    }
    handleChange(1);
  };

  useOnMount(() => {
    const mostChosenOptionKey = getMostChosenOptionKey(preferences, groupId);
    if (selectedProduct || !mostChosenOptionKey) return;
    const key = `${groupId}-${id}`;
    if (key === mostChosenOptionKey) {
      handleChange(preferences[mostChosenOptionKey].amount);
    }
  });

  return (
    <Styles className="RadioButtonModifier" disabled={disabled}>
      <div className="RadioButtonModifier__container">
        <Button
          className="RadioButtonModifier__button"
          id={id}
          onClick={onClickHandle}
          value={amount}
        >
          <p className={`RadioButtonModifier__text ${textActiveClassName}`}>
            {name}
          </p>
          <ModifierPrices totals={totals} />
        </Button>
        <button
          onClick={onClickHandle}
          className={`RadioButtonModifier__icon ${iconActiveClassName}`}
        />
      </div>
      {groups?.length > 0 ? (
        <ThirdLevelModifiers disabled={!amount} modifierGroups={groups} />
      ) : null}
    </Styles>
  );
};

RadioButtonModifier.defaultProps = {};

export default RadioButtonModifier;
