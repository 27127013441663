import { useGeo, useGlobal, useStores } from "@sushicorp/contexts";
import { useShippingAddress } from "@sushicorp/contexts";
import { memo, useMemo } from "react";

import Styles from "./AddressCheckbox.styles";
import { AddressCheckboxProps as Props } from "./AddressCheckbox.types";
import Checkbox from "components/global/Checkbox/Checkbox";
import useI18n from "hooks/useI18n";

const AddressCheckbox: React.FC<Props> = props => {
  const { className, checked, setChecked, isLoading } = props;
  const t = useI18n();
  const { selectedLocation } = useGeo();
  const { drawerConfig } = useGlobal();
  const { location: tempLocation } = drawerConfig;
  const { selectedShippingAddress: shipping } = useShippingAddress();
  const { country, livingPlace } = shipping ?? {};
  const { name: countryName } = country ?? {};

  const { fields } = livingPlace ?? {};
  const [field] = fields ?? [];
  const { label = "" } = field ?? {};
  const city = label.split(",")?.slice(-2, -1);
  const cityName = `${city}`.replace(/\d+/g, "").trim();

  const { selectedStore } = useStores();
  const { storeName } = selectedStore ?? {};

  const location = useMemo(() => {
    if (selectedLocation) return selectedLocation;
    const rawLocation = label.length ? label : tempLocation;
    const places = rawLocation.split(",").slice(0, -2);
    const place = places.join(",").trim();
    return place;
  }, [label, selectedLocation, tempLocation]);

  const address = useMemo(() => {
    if (countryName && cityName) {
      return `${location} (${countryName}, ${cityName})`;
    }
    return `${location}`;
  }, [location, cityName, countryName]);

  const checkHandler = () => {
    setChecked(!checked);
  };

  return (
    <Styles className={`AddressCheckbox ${className}`} disabled={isLoading}>
      <Checkbox
        name="confirmLocation"
        checked={checked}
        onChange={checkHandler}
        disabled={isLoading}
        label={
          <p className="AddressCheckbox__text">
            {t.cart.confirmAddress.first}{" "}
            <span className="AddressCheckbox__text--bold">{storeName}</span>{" "}
            {location || shipping ? (
              <span>
                {t.cart.confirmAddress.second}{" "}
                <span className="AddressCheckbox__text--bold">{address}</span>
              </span>
            ) : null}
          </p>
        }
      />
    </Styles>
  );
};

export default memo(AddressCheckbox);
