import { useCountries } from "@sushicorp/contexts";
import { formatByCurrency } from "artisn-ui-react";
import React from "react";

import { getFormatPointsOption } from "./ProductBasePriceInfo.helpers";
import Styles from "./ProductBasePriceInfo.styles";
import { ProductBasePriceInfoProps as Props } from "./ProductBasePriceInfo.types";
import CONSTANTS from "config/constants";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";

const { WITH_LOYALTY } = CONSTANTS.FEATURE_FLAGS;

const ProductBasePriceInfo: React.FC<Props> = props => {
  const { showUnits = false, largePrice } = props;
  const { className, productTotals, orderTotal } = props;
  const { unitNetPrice, unitPoints } = productTotals ?? {};
  const normal = orderTotal ? orderTotal : unitNetPrice;
  const { selectedCountry } = useCountries();
  const defaultFormatter = useDefaultCurrencyFormatter();

  return (
    <Styles
      largePrice={largePrice}
      className={`ProductBasePriceInfo ${className}`}
    >
      <p className="ProductBasePriceInfo--normal">
        {formatByCurrency(normal ?? 0, defaultFormatter)}
        {showUnits ? " c/u" : null}
      </p>
      {WITH_LOYALTY && unitPoints && !isNaN(unitPoints) ? (
        <>
          <div className="ProductBasePriceInfo__point" />
          <p className="ProductBasePriceInfo--points">
            {formatByCurrency(
              Number(unitPoints ?? 0),
              getFormatPointsOption(selectedCountry),
              true
            )}
          </p>
        </>
      ) : null}
    </Styles>
  );
};

ProductBasePriceInfo.defaultProps = {
  className: ""
};

export default ProductBasePriceInfo;
