import { useCatalogues } from "@sushicorp/contexts";
import { events } from "artisn/analytics";
import { useEffect, useRef } from "react";

import useCheckout from "components/checkout/Checkout/context/checkout/checkout.context.hooks";
import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import { Workflow } from "types/workflow.types";

const { logSelectWorkflow } = events.workflow;
const { TRANSFER_ANONYMOUS_ID } = CONSTANTS.STORAGE;

const useListenGlobals = () => {
  const { selectedCatalogue } = useCatalogues();
  const { analyticsInitialized } = useAnalytics();
  const { name } = selectedCatalogue ?? {};
  const selectWorkflowLogged = useRef(false);
  const { setTransferAnonymousId, transferAnonymousId } = useCheckout();

  useEffect(() => {
    if (selectWorkflowLogged.current || !analyticsInitialized) return;
    logSelectWorkflow({
      workflow: name as Workflow
    });
    selectWorkflowLogged.current = true;
  }, [analyticsInitialized, name]);

  useEffect(() => {
    (async () => {
      if (!transferAnonymousId) return;
      localStorage.setItem(TRANSFER_ANONYMOUS_ID, transferAnonymousId);
    })();
  }, [transferAnonymousId]);

  useEffect(() => {
    (async () => {
      const value = localStorage.getItem(TRANSFER_ANONYMOUS_ID);
      const storageAnonymousId = value;
      if (!storageAnonymousId) return;
      setTransferAnonymousId(storageAnonymousId);
      localStorage.setItem(TRANSFER_ANONYMOUS_ID, storageAnonymousId);
    })();
  }, [transferAnonymousId, setTransferAnonymousId]);
};

export default useListenGlobals;
