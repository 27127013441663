import { useShoppingCart, useStores } from "@sushicorp/contexts";
import { Store } from "artisn/types";
import { Clickable } from "artisn-ui-react";
import React, { useState, useEffect } from "react";

import Styles, { ModalHeaderStyled } from "./ChooseStoreModal.styles";
import { ChooseStoreModalProps as Props } from "./ChooseStoreModal.types";
import ChooseStore from "../ChooseStore/ChooseStore";
import ChooseStoreInMap from "../ChooseStoreInMap/ChooseStoreInMap";
import { InfoShoppingCartModal } from "components/global/InfoShoppingCartModal/InfoShoppingCartModal";
import useInfoShoppingCartModal from "components/global/InfoShoppingCartModal/InfoShoppingCartModal.hooks";
import Modal from "components/global/Modal/Modal";
import StoreDetailsPickup from "components/global/StoreDetailsPickup/StoreDetailsPickup";
import useBenefit from "hooks/useBenefit/useBenefit";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import useValidateStoreGeo from "hooks/useValidateStoreGeo";
import { createErrorNotification } from "utils/notifications.utils";

import ChevronLeftSVG from "../../../../public/assets/images/chevron-left-black.svg";
import CloseSVG from "../../../../public/assets/images/close.svg";

const ChooseStoreModal: React.FC<Props> = props => {
  const { opened, onClose } = props;
  const { setSelectedStore } = useStores();
  const [previousStep, setPreviousStep] = useState(1);
  const [step, setStep] = useState(1);
  const [mapStore, setMapStore] = useState<Store>();
  const [title, setTitle] = useState("Tiendas");
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const { shoppingCart } = useShoppingCart();
  const { validateStoreGeo } = useValidateStoreGeo();
  const { keepCartHandler } = useInfoShoppingCartModal();
  const { reApplyBenefit } = useBenefit();

  const backHandler = () => {
    setStep(prev => {
      if (previousStep === 1) return 1;
      return prev - 1;
    });
  };

  const closeHandler = () => {
    setStep(1);
    setMapStore(undefined);
    onClose?.();
  };

  const onSelectedStore = (store: Store) => {
    setSelectedStore(store);
    setMapStore(store);
    onClose?.();
  };

  const clickHandler = async (store: Store) => {
    const { latitude: lat, longitude: lng } = store ?? {};
    const storeCoordinates = { lat, lng };
    if (!isEmptyShoppingCart) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (typeof isConfirmed === "undefined") return;
        if (!isConfirmed) {
          const newStore = await validateStoreGeo(storeCoordinates);
          const [benefit] = shoppingCart?.benefits ?? [];
          await keepCartHandler(storeCoordinates);
          onSelectedStore(store);
          setSelectedStore(newStore);
          await reApplyBenefit(benefit);
          return;
        }
        emptyCartHandler();
        onSelectedStore(store);
      } catch (e) {
        console.error(e);
        createErrorNotification(e.message);
      }
      return;
    }
    onSelectedStore(store);
  };

  const modalHeaderNode = (
    <ModalHeaderStyled step={step}>
      {step > 1 ? (
        <Clickable className="ChooseStoreModal__go-back" onClick={backHandler}>
          <ChevronLeftSVG />
        </Clickable>
      ) : null}
      <p className="ChooseStoreModal__header">{title}</p>
    </ModalHeaderStyled>
  );

  const chooseStoreNode =
    step === 1 ? (
      <ChooseStore
        mapStore={mapStore}
        setStep={setStep}
        setPreviousStep={setPreviousStep}
        setMapStore={setMapStore}
        onSelectedStore={clickHandler}
        onClose={closeHandler}
      />
    ) : null;

  const chooseStoreInMapNode =
    step === 2 ? (
      <ChooseStoreInMap
        mapStore={mapStore}
        setStep={setStep}
        onClose={onClose}
        setMapStore={setMapStore}
        setPreviousStep={setPreviousStep}
      />
    ) : null;

  const storeDetailsNode =
    step === 3 ? <StoreDetailsPickup store={mapStore} /> : null;

  useEffect(() => {
    if (step === 1) setTitle("Tiendas");
    if (step === 2) setTitle("Buscar en el mapa");
    if (step === 3) setTitle("Información");
  }, [step]);

  return (
    <Modal
      {...props}
      closeOnClickOutside
      opened={opened}
      header={modalHeaderNode}
      backdropConfig={{ onClick: closeHandler }}
      onClose={closeHandler}
      closeIcon={<CloseSVG />}
    >
      <Styles className="ChooseStoreModal">
        {chooseStoreNode}
        {chooseStoreInMapNode}
        {storeDetailsNode}
      </Styles>
    </Modal>
  );
};

ChooseStoreModal.defaultProps = {};

export default ChooseStoreModal;
