import React from "react";

import Styles from "./Divider.styles";
import { DividerProps as Props } from "./Divider.types";

const Divider: React.FC<Props> = props => {
  const { className, showMobile = true, ...rest } = props;
  return (
    <Styles
      className={`Divider ${className}`}
      showMobile={showMobile}
      {...rest}
    />
  );
};

Divider.defaultProps = {
  className: ""
};

export default Divider;
