import { useShoppingCart, useVendors } from "@sushicorp/contexts";
import { buildArtisnHeaders } from "@sushicorp/services";
import { listenBenefitsWallet } from "artisn/shopping-cart";
import { useMemo } from "react";
import { useQuery } from "react-query";

import { UseCouponValues } from "./useCoupons.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;

// Coupons custom hook functions
const useCoupons = ():
  | UseCouponValues
  | Record<keyof UseCouponValues, undefined> => {
  const { uid, isAnonymous = false } = useAuth();
  const { selectedVendorId: vendorId } = useVendors();
  const { setBenefitsWallet: setWallet } = useShoppingCart();
  const query = useQuery<Headers>(["artisn-headers"], buildArtisnHeaders, {
    enabled: !!uid
  });
  const { data: headers } = query;

  const config = useMemo(() => {
    if (!uid) return null;
    return {
      accountId: ACCOUNT_ID,
      apiURL: API_URL,
      anonymous: isAnonymous,
      customerId: uid,
      vendorId: vendorId
    };
  }, [uid, isAnonymous, vendorId]);

  return useMemo(() => {
    if (!config || !headers) return {};
    return {
      listenBenefitsWallet: () => {
        return listenBenefitsWallet(a => setWallet(a ?? null), config, headers);
      }
    };
  }, [config, headers, setWallet]);
};

export default useCoupons;
