// Schedule helper functions and data
import { getHours, getMinutes } from "@sushicorp/utils";
import { Schedule } from "artisn/types";
import dayjs from "dayjs";

import CONSTANTS from "config/constants";

const { HOUR_MINUTE } = CONSTANTS.DATES_FORMAT;

export const formattingHours = (timeInSeconds: number, timeFormat?: string) => {
  return dayjs()
    .hour(getHours(timeInSeconds))
    .minute(getMinutes(timeInSeconds))
    .format(timeFormat ?? HOUR_MINUTE);
};

export const getDaySchedule = (day: string) => {
  const localDay = new Date().getDay();
  const days = [
    "DOMINGO",
    "LUNES",
    "MARTES",
    "MIÉRCOLES",
    "JUEVES",
    "VIERNES",
    "SÁBADO"
  ];
  return day.toUpperCase() === days[localDay];
};

/**
 * Function to format a single schedule time to a string.
 *
 * @since 1.0.0
 * @param {number} time Time in seconds to format.
 * @returns {string}
 */
export const formatScheduleTimeHelper = (time: number) => {
  return `${formattingHours(time, "hh")}h${formattingHours(time, "mm")}`;
};

const weekDayHelper = (day: string) => {
  const locale = navigator.language.slice(0, 2);

  if (locale === "es") {
    switch (day) {
      case "MONDAY":
        return "Lunes a Viernes";
      case "SATURDAY":
        return "Sábados";
      case "SUNDAY":
        return "Domingos";
      default:
        return "???";
    }
  }

  if (locale === "en") {
    switch (day) {
      case "MONDAY":
        return "Monday to Friday";
      case "SATURDAY":
        return "Saturdays";
      case "SUNDAY":
        return "Sundays";
      default:
        return "???";
    }
  }
};

/**
 * Function to format the store's schedules to a string.
 *
 * @since 1.0.0
 * @param {Schedule} schedule Schedule object to format.
 * @param {string} fromText Text to show before the "from" time.
 * @param {string} toText Text to show before the "to" time.
 * @returns {string}
 */
export const formatScheduleOutputHelper = (
  schedule: Schedule,
  fromText: string,
  toText: string
) => {
  const { day, from, to } = schedule;

  return `${weekDayHelper(day)} ${fromText} ${formatScheduleTimeHelper(
    from
  )} ${toText} ${formatScheduleTimeHelper(to)}`;
};
