import { useShippingAddress, useStores } from "@sushicorp/contexts";
import { useShoppingCart } from "@sushicorp/contexts";
import { useDeleteShippingAddress } from "@sushicorp/services";
import { usePutShippingAddress } from "@sushicorp/services";
import { trimFields } from "@sushicorp/utils";
import { defaultFunction } from "@sushicorp/utils";
import { getShoppingCart } from "artisn/shopping-cart";
import React, { useRef, useState } from "react";

import { getNickname } from "./ChooseAddress.helpers";
import Styles, { DeleteAddressModal } from "./ChooseAddress.styles";
import { ChooseAddressProps as Props } from "./ChooseAddress.types";
import { InfoShoppingCartModal } from "components/global/InfoShoppingCartModal/InfoShoppingCartModal";
import useInfoShoppingCartModal from "components/global/InfoShoppingCartModal/InfoShoppingCartModal.hooks";
import ListItem from "components/global/ListItem/ListItem";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useBenefit from "hooks/useBenefit/useBenefit";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import useI18n from "hooks/useI18n";
import useValidateStoreGeo from "hooks/useValidateStoreGeo";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { applyAlterDeliveryBenefitUtility } from "utils/shoppingCart.utils";
import { getBenefitOfShoppingCartUtility } from "utils/shoppingCart.utils";
import { removeBenefitsUtility } from "utils/shoppingCart.utils";
import { validateShoppingCartUtility } from "utils/shoppingCart.utils";

import LocationSVG from "../../../../public/assets/images/location.svg";
import TrashSVG from "../../../../public/assets/images/trash.svg";

const { ARTISN } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;

const ChooseAddress: React.FC<Props> = props => {
  const t = useI18n();
  const auth = useAuth();
  const { shippingAddress, actions } = props;
  const { onEdit: onPressEdit = defaultFunction, dropdownFixed } = props;
  const { mainStreet, lat, lng } = shippingAddress;
  const { id, default: isDefault } = shippingAddress;
  const { nickname: shippingAddressNickname } = shippingAddress;
  const nickname = getNickname(shippingAddressNickname);
  const { isAnonymous = false, uid } = auth;
  const { keepCartHandler } = useInfoShoppingCartModal();
  const { reApplyBenefit } = useBenefit();
  const { selectedShippingAddress } = useShippingAddress();
  const { shoppingCart } = useShoppingCart();
  const { setSelectedShippingAddress } = useShippingAddress();
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const putState = usePutShippingAddress(auth);
  const { mutateAsync: updateAddress, isLoading: isPutLoading } = putState;
  const isActive = selectedShippingAddress?.id === id;
  const [openModal, setOpenModal] = useState(false);
  const isLoadingReappliedCouponRef = useRef(false);
  const { selectedStore, setSelectedStore } = useStores();
  const deleteState = useDeleteShippingAddress(auth);
  const { validateStoreGeo } = useValidateStoreGeo();
  const { mutate: deleteAddress, isLoading: isDeleteLoading } = deleteState;
  const isLoading = isPutLoading || isDeleteLoading;
  const activeRadioStyle = isActive ? "AddressList__item__radio--active" : "";

  const onEdit = () => {
    onPressEdit(shippingAddress);
  };

  const onDelete = () => {
    setOpenModal(true);
  };

  const onDefault = async () => {
    if (!uid) return;
    const newShippingAddress = trimFields(shippingAddress);
    // @ts-ignore
    const { lat, lng, validated } = newShippingAddress;
    try {
      isLoadingReappliedCouponRef.current = true;
      try {
        await updateAddress({
          ...newShippingAddress,
          // @ts-ignore
          validated: !!validated,
          default: true
        });
      } catch (error) {
        throw new Error(
          "Ocurrió un problema al intentar actualizar su dirección"
        );
      }
      const currentBenefit = getBenefitOfShoppingCartUtility(shoppingCart);
      const thereIsFreeDeliveryCouponApplied =
        !!currentBenefit &&
        currentBenefit.type === "ALTER_DELIVERY" &&
        !!selectedStore &&
        !!shoppingCart;
      if (thereIsFreeDeliveryCouponApplied) {
        await removeBenefitsUtility(
          selectedStore,
          currentBenefit,
          shoppingCart,
          uid,
          isAnonymous
        );
      }
      await validateShoppingCartUtility(lat, lng, isAnonymous, uid);
      if (thereIsFreeDeliveryCouponApplied) {
        const shoppingCart = await getShoppingCart({
          shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
          anonymous: isAnonymous,
          accountId: ACCOUNT_ID,
          customerId: uid
        });
        await applyAlterDeliveryBenefitUtility(
          currentBenefit,
          shoppingCart,
          uid
        );
      }
      isLoadingReappliedCouponRef.current = false;
    } catch (e) {
      isLoadingReappliedCouponRef.current = false;
      dismissErrorNotification();
      createErrorNotification(e.message);
    }
  };

  const onClose = () => setOpenModal(false);

  const deleteHandler = () => {
    if (isDefault) {
      onClose();
      dismissErrorNotification();
      createErrorNotification(t.errors.deleteDefaultAddress);
      return;
    }
    deleteAddress(id, {
      onError: () => {
        dismissErrorNotification();
        createErrorNotification(t.errors.deleteAddress);
        console.error(
          "DeleteAddressModal: An error occurred while trying to delete address data"
        );
      },
      onSuccess: () => {
        setSelectedShippingAddress(undefined);
        onClose();
      }
    });
  };

  const clickHandler = async () => {
    const coordinates = { lat, lng };

    if (!isEmptyShoppingCart) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (typeof isConfirmed === "undefined") return;
        if (!isConfirmed) {
          const newStore = await validateStoreGeo(coordinates);
          const [benefit] = shoppingCart?.benefits ?? [];
          await keepCartHandler(coordinates);
          await onDefault();
          setSelectedStore(newStore);
          await reApplyBenefit(benefit);
          return;
        }

        emptyCartHandler();
        onDefault();
        return;
      } catch (e) {
        console.error(e);
        createErrorNotification(e.message);
      }
      return;
    }
    onDefault();
  };

  return (
    <>
      <button
        className={`AddressList__item__radio ${activeRadioStyle}`}
        onClick={clickHandler}
      />
      <Styles
        className="ChooseAddress"
        isActive={isActive}
        disabled={isLoading}
      >
        <ListItem
          className="ChooseAddress__list-item"
          title={nickname}
          icon={<LocationSVG />}
          description={`${mainStreet}`}
          isLoading={
            isLoading && !isActive && isLoadingReappliedCouponRef.current
          }
          isDefault={isDefault}
          onEdit={onEdit}
          onDelete={onDelete}
          onDefault={onDefault}
          actions={actions}
          isFixed={dropdownFixed}
          isActive={!isLoadingReappliedCouponRef.current && isActive}
        />
        <DeleteAddressModal
          opened={openModal}
          className="DeleteAddressModal"
          icon={<TrashSVG />}
          title={t.profile.address.deleteAddress}
          description={t.profile.address.deleteAddressDescription}
          confirmText={t.common.delete}
          confirmAction={deleteHandler}
          cancelText={t.common.cancel}
          cancelAction={onClose}
          onClose={onClose}
        />
      </Styles>
    </>
  );
};

ChooseAddress.defaultProps = {};

export default ChooseAddress;
