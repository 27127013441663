import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ExtraProductsProvider } from "@sushicorp/contexts";
import { NotificationsContainer } from "artisn-ui-react";
import React, { useRef } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
// import { ReactQueryDevtools } from "react-query/devtools"
import { QueryClientProvider, QueryClient } from "react-query";
import { Hydrate } from "react-query/hydration";
import { Unmodal } from "react-unmodal";

import { ProvidersProps as Props } from "./Providers.types";
import CheckoutProvider from "components/checkout/Checkout/context/checkout/checkout.context";
import Error from "components/error/Error/Error";
import CONSTANTS from "config/constants";
import { queryClient } from "config/reactQuery.config";
import ArtisnProvider from "containers/ArtisnProvider/ArtisnProvider";
import ThemeProvider from "contexts/theme/theme.context";

const { GENERAL, TITLE } = CONSTANTS;
const { INCLUDED_LOGGER_ENVS } = GENERAL;

if (!(Bugsnag as any)._client) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_ENV_BUGSNAG_ID ?? "",
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: INCLUDED_LOGGER_ENVS,
    releaseStage: process.env.NODE_ENV,
    metadata: {
      client: TITLE,
      env: process.env.NODE_ENV
    }
  });
}

const ErrorBoundary =
  process.env.NODE_ENV === "production"
    ? Bugsnag.getPlugin("react")!.createErrorBoundary(React)
    : ReactErrorBoundary;

const Providers: React.FC<Props> = props => {
  const { children, dehydratedState } = props;
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) queryClientRef.current = queryClient;

  return (
    <ThemeProvider>
      <ErrorBoundary FallbackComponent={Error}>
        <QueryClientProvider client={queryClientRef.current}>
          <Hydrate state={dehydratedState}>
            {/* {process.env.NODE_ENV !== "test" ? <ReactQueryDevtools /> : null} */}
            <ExtraProductsProvider>
              <ArtisnProvider>
                <CheckoutProvider>
                  <NotificationsContainer />
                  <Unmodal.Provider>{children}</Unmodal.Provider>
                </CheckoutProvider>
              </ArtisnProvider>
            </ExtraProductsProvider>
          </Hydrate>
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

Providers.defaultProps = {};

export default Providers;
