import { ButtonGroup } from "artisn-ui-react";
import React from "react";

import Styles from "./BenefitsList.styles";
import { BenefitsListProps as Props } from "./BenefitsList.types";
import CouponDetail from "../CouponDetail/CouponDetail";
import useI18n from "hooks/useI18n";

import EmptySVG from "../../../../public/assets/images/empty-benefit-list.svg";

const { Button } = ButtonGroup;

const BenefitsList: React.FC<Props> = props => {
  const { benefits, showRemove = true, inCart = false } = props;
  const { selectedBenefit, setSelectedBenefit, inModal = false } = props;
  const t = useI18n();
  const empty = benefits && benefits.length === 0;

  const emptyNode = (
    <div className="BenefitsList__empty">
      <EmptySVG />
      <p className="BenefitsList__empty__title">{t.coupons.addCoupons}</p>
      <p className="BenefitsList__empty__message">{t.coupons.couponsInfo}</p>
    </div>
  );

  const changeHandler = (benefitId?: number) => {
    const benefit = benefits?.find(item => item.benefitId === benefitId);
    setSelectedBenefit?.(benefit);
  };

  return (
    <Styles className="BenefitsList">
      {empty ? emptyNode : null}
      <ButtonGroup
        className="BenefitsList__buttonGroup"
        active={selectedBenefit?.benefitId.toString()}
        onChange={e => changeHandler(e.target.value)}
      >
        {benefits?.map((item, index) => {
          const { benefitId } = item;
          const selected = selectedBenefit?.benefitId === benefitId;
          return (
            <Button
              disabled={!inCart}
              className="BenefitsList__button"
              value={benefitId}
              type="LINK"
              id={benefitId.toString()}
              key={index}
            >
              <CouponDetail
                key={index}
                inCart={inCart}
                showRemove={showRemove}
                benefit={item}
                selected={selected}
                showRadio={inModal}
              />
            </Button>
          );
        })}
      </ButtonGroup>
    </Styles>
  );
};

BenefitsList.defaultProps = {};

export default BenefitsList;
