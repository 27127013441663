import React from "react";

import Styles from "./Switch.styles";
import { SwitchProps as Props } from "./Switch.types";

const Switch: React.FC<Props> = props => {
  const { ...rest } = props;

  return <Styles {...rest} />;
};

Switch.defaultProps = {};

export default Switch;
