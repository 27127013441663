import { toSentenceCaseUtility as toSentence } from "@sushicorp/utils";
import React from "react";

import Styles from "./CounterModifier.styles";
import { CounterModifierProps as Props } from "./CounterModifier.types";
import ModifierPrices from "../ModifierPrices/ModifierPrices";
import ThirdLevelModifiers from "../ThirdLevelModifiers/ThirdLevelModifiers";
import Counter from "components/global/Counter/Counter";

const CounterModifier: React.FC<Props> = props => {
  const { modifier, maxDisabled = false, disabled = false } = props;
  const { amount, name, max, min, totals, groups } = modifier;
  const { handleChange } = modifier;
  const totalDisabled = maxDisabled && !amount;
  const activeClassName = amount ? "CounterModifier__title--active" : "";

  return (
    <Styles className="CounterModifier">
      <div className="CounterModifier__container">
        <div className="CounterModifier__info">
          <p className={`CounterModifier__title ${activeClassName}`}>
            {toSentence(name)}
          </p>
          <ModifierPrices totals={totals} />
        </div>
        <Counter
          className="CounterModifier__counter"
          initialValue={amount ?? min}
          max={max}
          min={min}
          onSubtract={value => handleChange(value)}
          onAdd={value => handleChange(value)}
          disabled={disabled ?? totalDisabled}
          maxDisabled={maxDisabled}
        />
      </div>
      {groups?.length > 0 ? (
        <ThirdLevelModifiers disabled={!amount} modifierGroups={groups} />
      ) : null}
    </Styles>
  );
};

CounterModifier.defaultProps = {};

export default CounterModifier;
