import Head from "next/head";
import React from "react";

import { backdropConfig } from "./Error.helpers";
import Styles from "./Error.styles";
import { ErrorProps as Props } from "./Error.types";
import Button from "../Button/Button";
import useI18n from "hooks/useI18n";

import ErrorSVG from "../../../../public/assets/images/order/error.svg";

const Error: React.FC<Props> = props => {
  const { className = "", show, title, description } = props;
  const { closeModalHandler } = props;

  const t = useI18n();

  return (
    <Styles
      backdropConfig={backdropConfig}
      className={`Error ${className}`}
      opened={show}
      color="--var-palette-white"
    >
      <Head>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
        <title>Oops ocurrió un error</title>
      </Head>
      <ErrorSVG />
      <p className="Error__text Error__text--title">
        {title ?? t.errors.general}
      </p>
      {description ? (
        <p className="Error__text Error__text--description">{description}</p>
      ) : null}
      <Button className="Error__button" onClick={closeModalHandler}>
        {t.errors.back}
      </Button>
    </Styles>
  );
};

Error.defaultProps = {};

export default Error;
