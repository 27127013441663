// Orders context
import { OrderStep } from "@sushicorp/types";
import React, { createContext, useState, useMemo } from "react";

import { LiveOrders } from "./orders.context.types";
import { OrdersProviderValue } from "./orders.context.types";
import { OrdersProviderProps as Props } from "./orders.context.types";

// @ts-ignore
export const OrdersContext = createContext<OrdersProviderValue>({});

const OrdersProvider: React.FC<Props> = props => {
  const [liveOrders, setLiveOrders] = useState<LiveOrders>();
  const [selectedLiveOrderStep, setSelectedLiveOrderStep] =
    useState<OrderStep>();

  const value: OrdersProviderValue = useMemo(() => {
    return {
      liveOrders,
      setLiveOrders,
      selectedLiveOrderStep,
      setSelectedLiveOrderStep
    };
  }, [liveOrders, selectedLiveOrderStep]);

  return (
    <OrdersContext.Provider value={value}>
      {props.children}
    </OrdersContext.Provider>
  );
};

export default OrdersProvider;
