import { useBillingData } from "@sushicorp/contexts";
import { Modal } from "artisn-ui-react";
import Lottie from "lottie-react-web";
import React from "react";

import Styles from "./BillingDataFoundedModal.styles";
import { BillingDataFoundedModalProps as Props } from "./BillingDataFoundedModal.types";
import Button from "../Button/Button";
import useI18n from "hooks/useI18n";

import BillingDataAnimationJSON from "../../../../public/assets/animations/billing-data.json";
import CloseSVG from "../../../../public/assets/images/close.svg";

const BillingDataFoundedModal: React.FC<Props> = () => {
  const t = useI18n();
  const { billingFoundedModalConfig } = useBillingData();

  const { show } = billingFoundedModalConfig ?? {};
  const { onClickImportData = () => {} } = billingFoundedModalConfig ?? {};
  const { onClose = () => {} } = billingFoundedModalConfig ?? {};
  const { foundBillingDataName = "user" } = billingFoundedModalConfig ?? {};

  return (
    <Modal
      opened={!!show}
      onClose={onClose}
      closeIcon={<CloseSVG />}
      className="BillingDataFoundedModal__modal"
      closeOnClickOutside
    >
      <Styles className="BillingDataFoundedModal">
        <div className="BillingDataFoundedModal__icon-wrapper">
          <div className="BillingDataFoundedModal__icon">
            <Lottie
              width={136}
              height={136}
              options={{
                animationData: BillingDataAnimationJSON,
                autoplay: true,
                loop: true
              }}
            />
          </div>
        </div>
        <p className="BillingDataFoundedModal__header">
          {t.profile.greeting} {foundBillingDataName}
        </p>
        <p className="BillingDataFoundedModal__header">
          {t.billing.youWantPreviousData}
        </p>
        <Button
          className="BillingDataFoundedModal__button"
          onClick={onClickImportData}
        >
          {t.billing.importData}
        </Button>
        <Button
          className="BillingDataFoundedModal__button"
          type="BORDER"
          onClick={onClose}
        >
          {t.billing.continueWithoutData}
        </Button>
      </Styles>
    </Modal>
  );
};

BillingDataFoundedModal.defaultProps = {};

export default BillingDataFoundedModal;
