import { useCatalogues, useExtraProducts } from "@sushicorp/contexts";
import { useGeo } from "@sushicorp/contexts";
import { usePackageCost, useStores } from "@sushicorp/contexts";
import { useShoppingCart as useShoppingCartContext } from "@sushicorp/contexts";
import { getAdditionalProducts } from "@sushicorp/utils";
import { emptyShoppingCart, updateShoppingCart } from "artisn/shopping-cart";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { deleteShoppingCart } from "artisn/shopping-cart";
import { subtractProduct, removeProduct } from "artisn/shopping-cart";
import { listenShoppingCart, addProduct } from "artisn/shopping-cart";
import { replaceProduct } from "artisn/shopping-cart";
import { useMemo } from "react";
import invariant from "tiny-invariant";

import { cleanShoppingCartAlerts } from "./useShoppingCart.helpers";
import { getShoppingCartProductByHash } from "./useShoppingCart.helpers";
import { UseShoppingCartValues } from "./useShoppingCart.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";

const { ACCOUNT_ID } = CONSTANTS.ARTISN;

// ShoppingCart custom hook functions
const useShoppingCart = ():
  | UseShoppingCartValues
  | Record<keyof UseShoppingCartValues, undefined> => {
  const { uid, isAnonymous = false } = useAuth();
  const { selectedCatalogueId: catalogueId } = useCatalogues();
  const { setShoppingCart } = useShoppingCartContext();
  const { setExtraProducts } = useExtraProducts();
  const { setSelectedPackageCost } = usePackageCost();
  const { defaultStore } = useStores();
  let { selectedStore: store } = useStores();
  store = typeof store === "undefined" ? defaultStore : store;
  const channelId = +catalogueId;
  const { selectedCoordinates: coordinates } = useGeo();
  const { lat, lng } = coordinates ?? {};
  const { latitude: storeLat, longitude: storeLng } = store ?? {};

  const config = useMemo(() => {
    if (!uid || !store || !ACCOUNT_ID) return null;
    return {
      accountId: +ACCOUNT_ID,
      anonymous: isAnonymous,
      customerId: uid,
      store
    };
  }, [isAnonymous, store, uid]);

  return useMemo(() => {
    if (!config) return {};
    const { accountId } = config;

    if (!accountId) return {};

    return {
      listenShoppingCart: () => {
        return listenShoppingCart(cart => {
          // TODO: llevar al sdk
          // cart?.id === "" Just created but not yet ready.
          // cart?.id === null is when cart is rebuilt from backend.
          // if (cart?.id === "" || cart?.id === null) return;
          // @ts-ignore
          setShoppingCart(cart ?? null);
          if (!cart) {
            setSelectedPackageCost(undefined);
            setExtraProducts(undefined);
            return;
          }
          const products = getShoppingCartProducts(cart);
          const extraProducts = getAdditionalProducts(products);
          setExtraProducts(extraProducts);
        }, config);
      },
      // TODO: implementar lógica que abre popover de producto nuevo cuando no hay ese hash
      addProduct: options => {
        const latitude = lat ?? storeLat;
        const longitude = lng ?? storeLng;
        invariant(!!latitude && !!longitude, "No hay ubicación seleccionada");
        const { product, ...rest } = options;
        const newConfig = {
          createCart: { channelId, latitude, longitude },
          ...config,
          ...rest
        };
        return addProduct(options.product, newConfig);
      },
      subtractProduct: options => {
        const { product, ...rest } = options;
        const newConfig = { ...config, ...rest };
        return subtractProduct(options.product, newConfig);
      },
      removeProduct: options => {
        const { product, ...rest } = options;
        const newConfig = { ...config, ...rest };
        return removeProduct(options.product, newConfig);
      },
      replaceProduct: options => {
        const { product, ...rest } = options;
        const newConfig = { ...config, ...rest };
        return replaceProduct(options.product, newConfig);
      },
      emptyShoppingCart: (options = {}) => {
        const newConfig = { ...config, ...options };
        return emptyShoppingCart(newConfig);
      },
      updateShoppingCart: cb => {
        return updateShoppingCart(cb, config);
      },
      getShoppingCartProductByHash: options => {
        const { cart, hash } = options;
        return getShoppingCartProductByHash(cart, hash, config.store.storeId);
      },
      deleteShoppingCart: (options = {}) => {
        const newConfig = { ...config, ...options };
        return deleteShoppingCart(newConfig);
      },
      cleanShoppingCartAlerts
    };
  }, [
    config,
    setShoppingCart,
    setExtraProducts,
    setSelectedPackageCost,
    lat,
    storeLat,
    lng,
    storeLng,
    channelId
  ]);
};

export default useShoppingCart;
