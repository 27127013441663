import { useStores } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { DELIVERY_CATALOGUE } from "@sushicorp/utils";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { updateShoppingCart } from "artisn/shopping-cart";
import { CartProduct, ShoppingCart } from "artisn/types";
import { useEffect } from "react";

import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";

const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = CONSTANTS.ARTISN;

const useListenCatalogue = () => {
  const { isAnonymous = false, uid } = useAuth();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedStore } = useStores();
  const isDelivery = selectedCatalogueId === DELIVERY_CATALOGUE.catalogueId;
  const shippingCost = useShippingCost();

  useEffect(() => {
    if (!uid) return;
    if (!selectedStore) return;
    updateShoppingCart(
      prevCart => {
        delete prevCart.pickUpTime;
        const { storeId } = selectedStore;
        const products = getShoppingCartProducts(prevCart);
        const newShoppingCart: ShoppingCart = {
          ...prevCart,
          channelId: +selectedCatalogueId,
          shippingCost: isDelivery ? shippingCost : null
        };
        if (!products.length) return newShoppingCart;
        const newProducts: Record<string, CartProduct> = {};
        products.forEach(product => {
          const { hash } = product;
          newProducts[hash] = product;
        });

        newShoppingCart.stores = {
          [storeId]: { ...selectedStore, products: newProducts, alerts: [] }
        };

        return newShoppingCart;
      },
      {
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous,
        customerId: uid,
        accountId: ACCOUNT_ID
      }
    );
  }, [
    isAnonymous,
    selectedCatalogueId,
    selectedStore,
    isDelivery,
    shippingCost,
    uid
  ]);
};

export default useListenCatalogue;
