import styled from "styled-components";

import { ModifierPricesStyledProps as Props } from "./ModifierPrices.types";

const ModifierPricesStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.8rem;

  .ModifierPrices {
    &__sum {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l35);
      line-height: 1.6rem;
    }

    &__unitPrice {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l80);
      line-height: 1.6rem;
    }
  }
`;

export default ModifierPricesStyled;
