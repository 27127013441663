// CartPayButton helper functions and data

import { getSanitizedProductTotals } from "@sushicorp/utils";
import { getShoppingCartTotal } from "artisn/shopping-cart";
import { ShoppingCart } from "artisn/types";
import { CartProduct } from "artisn/types";
import { formatByCurrency } from "artisn-ui-react";

import { ProductAlert } from "./CartPayButton.types";
import { currencyOptionsDefault } from "components/categories/FiltersAccordion/FilterAccordion.helpers";
import { getFormatPointsOption } from "components/global/ProductBasePriceInfo/ProductBasePriceInfo.helpers";
import { Country } from "types/country.types";

export const getCartMessage = (
  products: CartProduct[] | undefined,
  showModifiers: (product: CartProduct) => string,
  shoppingCart: ShoppingCart | undefined,
  selectedCountry: Country
) => {
  const totals = shoppingCart ? getShoppingCartTotal(shoppingCart) : undefined;
  const { subtotal, totalDiscounts, totalTaxes } = totals ?? {};
  const { totalPoints, shippingCost } = totals ?? {};
  const points = totalPoints ? " | " + totalPoints + " puntos" : "";
  const { currency, decimals } = selectedCountry;
  const total = totals
    ? `${formatByCurrency(totals.total, currencyOptionsDefault(currency, 0))}`
    : "$0.00";
  let response = "Tu carrito: \n";
  const { currency: defaultCurrency } = selectedCountry;
  const { currencySymbol: defaultSymbol } = selectedCountry;
  // If the local property is in "en-Us" then the dollar sign appears on the left
  const defaultFormatter = {
    currency: defaultCurrency,
    symbol: defaultSymbol,
    locale: "en-Us"
  };

  products?.forEach((product, index) => {
    const { name, amount } = product;
    const modifiers = showModifiers(product);
    const { totals } = getSanitizedProductTotals(product, { decimals });
    const { unitNetPrice, unitPoints } = totals ?? {};
    const normal = formatByCurrency(
      Number(unitNetPrice ?? 0),
      defaultFormatter
    );
    const productPoints = formatByCurrency(
      Number(unitPoints ?? 0),
      getFormatPointsOption(selectedCountry),
      true
    );

    response += `${index + 1}. ${name} ${amount ? amount + "x" : ""} ${
      "*" + normal + "*"
    } ${unitPoints && !isNaN(unitPoints) ? "*" + productPoints + "*" : ""}\n`;
    response += `${modifiers ? modifiers + "\n" : ""}`;
  });
  response += " \n¡Listo! aquí tienes el resumen y detalle de tu orden: \n";
  response += `${
    subtotal !== undefined
      ? "Subtotal: " +
        formatByCurrency(subtotal, currencyOptionsDefault(currency, 0)) +
        "\n"
      : ""
  }`;
  response += `${
    totalDiscounts !== null && totalDiscounts !== undefined
      ? "Descuentos: " +
        formatByCurrency(totalDiscounts, currencyOptionsDefault(currency, 0)) +
        "\n"
      : ""
  }`;
  response += `${
    totalTaxes !== null && totalTaxes !== undefined
      ? "Impuestos: " +
        formatByCurrency(totalTaxes, currencyOptionsDefault(currency, 0)) +
        "\n"
      : ""
  }`;
  response += `${
    shippingCost !== null && shippingCost !== undefined
      ? "Envio: " +
        formatByCurrency(shippingCost, currencyOptionsDefault(currency, 0)) +
        "\n"
      : ""
  }`;
  response += `${total ? "*Total a pagar: " + total + points + "*\n" : ""}`;
  response += "\nSelecciona unas de estas opciones para continuar: \n";
  response += "1️⃣ *Pagar ahora* \n";
  response += "2️⃣ *Editar carrito* \n";
  response += "3️⃣ *Seguir agregando* \n";
  return response;
};

/**
 * Helper to get product missing message.
 *
 * @since 1.0.0
 * @param {ProductAlert[]} alerts Product alerts.
 * @return Error message.
 */
export const getMissingProduct = (alerts: ProductAlert[]) => {
  const [error] = alerts?.filter(e => e.alert === "missingProduct") ?? [];
  return error?.message ?? "";
};
