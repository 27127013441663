// Hook to delete shopping cart

import { useShoppingCart } from "@sushicorp/contexts";
import { events } from "artisn/analytics";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { deleteShoppingCart } from "artisn/shopping-cart";
import { useCallback, useMemo } from "react";

import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";

const { logClearCart } = events.shoppingCart;
const { ARTISN } = CONSTANTS;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;

export const useDeleteShoppingCart = () => {
  const { shoppingCart, setTemporalBenefit } = useShoppingCart();
  const { isAnonymous = false, uid } = useAuth();
  const { id: shoppingCartId } = shoppingCart ?? {};
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const empty = (products && products.length === 0) || !shoppingCart;

  const emptyCartHandler = useCallback(() => {
    if (!shoppingCartId || empty) return;
    if (!uid) return;

    logClearCart({
      cartId: shoppingCartId
    });
    deleteShoppingCart({
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: isAnonymous,
      customerId: uid,
      accountId: ACCOUNT_ID
    });
    setTemporalBenefit(undefined);
  }, [empty, isAnonymous, setTemporalBenefit, shoppingCartId, uid]);

  return useMemo(
    () => ({
      emptyCartHandler,
      isEmptyShoppingCart: empty
    }),
    [emptyCartHandler, empty]
  );
};
