import { getMaxWidth, getMaxHeight } from "@sushicorp/utils";
import styled from "styled-components";

import { ModalHeaderStyledProps } from "./ModifierModal.types";
import { ModalIconStyledProps } from "./ModifierModal.types";
import { ModifierModalStyledProps as Props } from "./ModifierModal.types";
import Modal from "components/global/Modal/Modal";
import CONSTANTS from "config/constants";

const { tablet } = CONSTANTS.BREAKPOINTS;

const ModifierModalStyled = styled(Modal)<Props>`
  width: min(${getMaxWidth()}, 96rem);
  height: min(${getMaxHeight()}, 62.4rem);

  @media (max-width: ${tablet}px) {
    max-height: unset;
    min-width: unset;
    height: 100%;
    width: 100%;
  }

  .Modal__body {
    background-color: var(--palette-gray-s0-l95) !important;
  }

  .ModifierModal {
    &__bottom-content {
      border-top: 0.1rem solid var(--palette-gray-s0-l20-a20);
      background-color: var(--palette-white);
      width: 100%;
      position: fixed;
      bottom: 0rem;
      height: 8rem;
      display: flex;
      justify-content: flex-end;
      padding: 0 1.6rem;
      align-items: center;
    }
    &__button {
      padding: 1.2rem;
    }

    &__modifiers-group {
      padding: 2.4rem;
      padding-bottom: 8rem;

      .ModifiersGroup {
        &__pocket-title {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
`;

export const ModalHeaderStyled = styled.div<ModalHeaderStyledProps>`
  flex: 1;
  text-align: center;

  .ModifierModal {
    &__header {
      padding: 2.4rem 3.2rem;
      font-size: 2rem;
      font-weight: 700;
      color: var(--palette-black);
      background-color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        padding: 2.4rem;
        padding-right: 4.2rem;

        font-size: 1.6rem;
      }
    }
  }
`;

export const ModalIconStyled = styled.div<ModalIconStyledProps>`
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${tablet}px) {
    padding: 1.2rem 0.4rem;
  }
`;

export default ModifierModalStyled;
