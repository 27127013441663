import { useCatalogues } from "@sushicorp/contexts";
import { fetchNearbyStores } from "@sushicorp/services";
import { Google } from "@sushicorp/types";
import { useCallback, useMemo } from "react";

import useI18n from "./useI18n";

const useValidateStoreGeo = () => {
  const { selectedCatalogueId } = useCatalogues();
  const t = useI18n();

  const validateStoreGeo = useCallback(
    async (coordinates: Google.Coordinates) => {
      const { lat, lng } = coordinates;
      const stores = await fetchNearbyStores(lat, lng, selectedCatalogueId);
      if (!stores.length) throw new Error(t.errors.stores.noStores);
      const storeClosed = !stores?.[0].isOpen;
      if (storeClosed) throw new Error(t.errors.stores.storeClosed);
      return stores[0];
    },
    [selectedCatalogueId, t]
  );

  return useMemo(() => ({ validateStoreGeo }), [validateStoreGeo]);
};

export default useValidateStoreGeo;
