import styled from "styled-components";

import { DataProtectionModalStyledProps as Props } from "./DataProtectionModal.types";
import CONSTANTS from "config/constants";

const { desktop } = CONSTANTS.BREAKPOINTS;

const DataProtectionModalStyled = styled.div<Props>`
  width: 80vw;
  padding: 2rem;

  @media (min-width: ${desktop}px) {
    width: 50vw;
  }

  .DataProtectionModal {
    &__title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 2.4rem;
    }

    &__paragraph {
      padding-bottom: 1.2rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__checkbox {
      display: flex;
      align-items: center;
      margin-top: 2rem;

      &__label {
        margin-left: -0.4rem;
      }

      &__link {
        color: var(--palette-primary);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__button {
      width: 100%;
      margin-top: 2.4rem;
      padding-block: 1.2rem;
    }
  }
`;

export default DataProtectionModalStyled;
