import { useVendors } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { useProducts } from "@sushicorp/contexts";
import { useFetchProductDetails } from "@sushicorp/services";
import { toSentenceCaseUtility } from "@sushicorp/utils";
import React, { useEffect } from "react";

import Styles, { ModalHeaderStyled } from "./ProductModal.styles";
import { ModalIconStyled } from "./ProductModal.styles";
import { ProductModalProps as Props } from "./ProductModal.types";
import ProductMain from "components/product/ProductMain/ProductMain";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useWindowSize from "hooks/useWindowSize";
import { notify } from "utils/common.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";

import CloseSVG from "../../../../public/assets/images/close.svg";

const { FEATURE_FLAGS, BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;
const { WITH_PRODUCT_MODAL } = FEATURE_FLAGS;

const ProductModal: React.FC<Props> = props => {
  const { className, isOpen, onClose, productId, onOpenDrawer } = props;
  const { selectedVendorId } = useVendors();
  const { selectedCatalogueId } = useCatalogues();
  const { selectedStore } = useStores();
  const { selectedProduct, setSelectedProductForm } = useProducts();
  const { isAnonymous } = useAuth();
  const { width, height } = useWindowSize();

  const { storeId } = selectedStore ?? {};
  const productsDetailsResponse = useFetchProductDetails(
    productId,
    {
      catalogueId: selectedCatalogueId,
      storeId: storeId ?? 0,
      vendorId: selectedVendorId,
      withCategories: true
    },
    notify
  );
  const { data: productData, isLoading, isError } = productsDetailsResponse;
  const product = selectedProduct ?? productData;
  const { name = "" } = product ?? {};

  /**
   * Function to show the add to cart notification.
   *
   * @since 1.0.0
   */
  const showShoppingCartDrawer = () => {
    dismissAddToCartNotification();
    if (onOpenDrawer) onOpenDrawer();
  };

  /**
   * Function to close the modal.
   *
   * @since 1.0.0
   */
  const closeHandler = () => {
    onClose();
    setSelectedProductForm(undefined);
  };

  const modalHeaderNode = (
    <ModalHeaderStyled id="modal-header">
      <p className="ProductModal__header">{toSentenceCaseUtility(name)}</p>
    </ModalHeaderStyled>
  );

  const modalIconNode = (
    <ModalIconStyled>
      <CloseSVG />
    </ModalIconStyled>
  );

  useEffect(() => {
    const responsiveViewCallback = () => {
      if (width > mobile) return;
      const productModal: HTMLDivElement | null =
        document?.querySelector(".ProductModal");
      const modalActions: HTMLDivElement | null = document?.querySelector(
        ".ProductMain__actions"
      );
      if (!productModal || !modalActions) return;
      const modalHeight = productModal.clientHeight;
      const bottom = modalHeight - height;
      productModal.style.paddingBottom = `${bottom}px`;
      modalActions.style.bottom = `${bottom}px`;
    };

    const timeout = setTimeout(responsiveViewCallback, 1000);

    return () => clearTimeout(timeout);
  }, [productId, height, width]);

  return (
    <Styles
      className={`ProductModal ${className}`}
      header={modalHeaderNode}
      closeIcon={modalIconNode}
      opened={isOpen}
      onClose={onClose}
    >
      <ProductMain
        className="ProductModal__main"
        productId={productId}
        isLoading={isLoading}
        isError={isError}
        isSignedIn={!isAnonymous}
        product={product}
        isModal={WITH_PRODUCT_MODAL}
        showShoppingCartDrawer={showShoppingCartDrawer}
        onCloseModal={closeHandler}
      />
    </Styles>
  );
};

ProductModal.defaultProps = {
  className: ""
};

export default ProductModal;
