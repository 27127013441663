import { checkInit } from "artisn/analytics";
import React, { createContext, useEffect, useMemo, useState } from "react";

import { AnalyticsProviderProps as Props } from "./analytics.context.types";
import { AnalyticsProviderValue } from "./analytics.context.types";

// @ts-ignore
export const AnalyticsContext = createContext<AnalyticsProviderValue>();

const AnalyticsProvider: React.FC<Props> = props => {
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

  const value: AnalyticsProviderValue = useMemo(() => {
    return { analyticsInitialized };
  }, [analyticsInitialized]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setAnalyticsInitialized(checkInit());
  });

  return (
    <AnalyticsContext.Provider value={value}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
