// Hook to cancel a pending order
import { useCatalogues } from "@sushicorp/contexts";
import { useShoppingCart } from "@sushicorp/contexts";
import { useVendors } from "@sushicorp/contexts";
import { usePutCancelOrder } from "@sushicorp/services";
import { fetchRebuildCart } from "@sushicorp/services";
import { fetchIncompleteOrders } from "@sushicorp/services";
import { useMemo, useCallback } from "react";

import useAuth from "contexts/auth/auth.context.hooks";
import { notify } from "utils/common.utils";

const useCancelOrder = () => {
  const auth = useAuth();
  const { uid } = auth;
  const { selectedCatalogueId } = useCatalogues();
  const { shoppingCart } = useShoppingCart();
  const { mutateAsync: putCancelOrder } = usePutCancelOrder(auth);
  const { reset: resetPutCancelOrder } = usePutCancelOrder(auth);
  const { id: shoppingCartId } = shoppingCart ?? {};
  const { selectedVendorId } = useVendors();

  const cancelOrder = useCallback(async () => {
    if (!uid) return;
    try {
      const [incompleteOrder] = await fetchIncompleteOrders(
        selectedCatalogueId,
        selectedVendorId
      );
      const { id: orderId } = incompleteOrder ?? {};
      if (orderId) {
        await putCancelOrder(orderId);
        resetPutCancelOrder();
        await fetchRebuildCart(orderId, shoppingCartId);
      }
    } catch (e) {
      notify(e, "Cancel order", "useCancelOrder");
    }
  }, [
    uid,
    selectedCatalogueId,
    selectedVendorId,
    putCancelOrder,
    resetPutCancelOrder,
    shoppingCartId
  ]);

  return useMemo(() => ({ cancelOrder }), [cancelOrder]);
};

export default useCancelOrder;
