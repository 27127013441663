import { useFetchShippingAddresses } from "@sushicorp/services";
import React from "react";

import { getMatchingAddresses } from "./AddressList.helpers";
import Styles from "./AddressList.styles";
import { AddressListProps as Props } from "./AddressList.types";
import ChooseAddress from "../ChooseAddress/ChooseAddress";
import CommonPlaceholder from "components/global/CommonPlaceholder/CommonPlaceholder";
import ErrorBlock from "components/global/ErrorBlock/ErrorBlock";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import { notify } from "utils/common.utils";

import HouseLocationSVG from "../../../../public/assets/images/house-red.svg";

const AddressList: React.FC<Props> = props => {
  const t = useI18n();
  const auth = useAuth();
  const { actions, className, onEdit, onSelect, searchResult } = props;
  const { dropdownFixed } = props;
  const { uid } = auth;
  const shippingAddresses = useFetchShippingAddresses(auth, notify);
  const { data: addressList, isLoading, isError } = shippingAddresses;
  const empty = addressList && addressList.length === 0;

  const placeholdersNode = (
    <>
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
      <CommonPlaceholder />
    </>
  );

  const emptyNode = (
    <div className="AddressList__empty">
      <div className="AddressList__empty__icon">
        <HouseLocationSVG className="AddressList__student-icon" />
      </div>
      <p className="AddressList__empty__title">
        {t.profile.address.saveAddress}
      </p>
      <p className="AddressList__empty__description">
        {t.profile.address.descriptionEmptyAddresses}
      </p>
    </div>
  );

  const addressListNode = () => {
    if (!searchResult?.length) {
      return addressList?.map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onEdit={onEdit}
            onClick={onSelect}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
    }
    return addressList
      ?.filter(address => getMatchingAddresses(address, searchResult))
      .map(address => (
        <div key={address.id} className="AddressList__item">
          <ChooseAddress
            shippingAddress={address}
            actions={actions}
            onClick={onSelect ?? onEdit}
            dropdownFixed={dropdownFixed}
          />
        </div>
      ));
  };

  return (
    <Styles className={`AddressList ${className ?? ""}`}>
      {isLoading ? placeholdersNode : null}
      {empty && !searchResult ? emptyNode : null}
      {isError ? <ErrorBlock queryKey={[uid, "addresses"]} /> : null}
      {!isLoading && !empty ? addressListNode() : null}
    </Styles>
  );
};

AddressList.defaultProps = {};

export default AddressList;
