// AddressForm helper functions and data

import { PutShippingAddressPayload } from "@sushicorp/services";
import { PostShippingAddressPayload } from "@sushicorp/services";
import { validationRules } from "@sushicorp/utils";
import { LivingPlace, ShippingAddress } from "artisn/types";
import * as yup from "yup";

import { AddressFormValues } from "./AddressForm.types";
import { Country } from "types/country.types";
import { Google } from "types/geo.types";

const { requiredString } = validationRules;

export const AddressFormSchema = yup.object().shape({
  nickname: requiredString,
  mainStreet: requiredString,
  secondaryStreet: requiredString,
  reference: requiredString
});

/**
 * Helper to return a default living place object.
 *
 * @since 1.0.0
 * @param {string} additionalInfo Address additional info.
 * @param {string} comment Address comments.
 * @returns LivingPlace object.
 */
export const getDefaultLivingPlaceHelper = (
  additionalInfo?: string,
  comment?: string
): LivingPlace => {
  return {
    id: 1,
    name: "",
    active: 0,
    countryId: 0,
    fields: [
      {
        id: "additional_data",
        // HACK: Sirve para conservar las direcciones resultados de la API de Google
        label: additionalInfo ?? "",
        rules: {
          max: 0,
          min: 0
        },
        messages: {
          max: "0",
          min: "0"
        },
        value: "Casa"
      },
      {
        id: "comment",
        label: "comment",
        rules: {
          max: 0,
          min: 0
        },
        messages: {
          max: "0",
          min: "0"
        },
        value: comment ?? ""
      }
    ]
  };
};

/**
 * Helper to get format living place data.
 *
 * @since 1.0.0
 * @param {LivingPlace} livingPlace Living place data.
 * @param {string} comment Comment address.
 * @returns Updated with the comment value.
 */
export const getFormatLivingPlaceHelper = (
  livingPlace: LivingPlace,
  comment?: string
) => {
  return {
    ...livingPlace,
    fields: livingPlace.fields.map(field => {
      if (field.id === "comment") {
        const { value } = field;
        return {
          ...field,
          value: comment ? comment : value
        };
      }
      return field;
    })
  };
};

export const getSelectedPostPayload = (
  form: AddressFormValues,
  mapAddress: Google.Geocode,
  country: Country
): PostShippingAddressPayload => {
  const { formatted_address, geometry } = mapAddress ?? {};
  const { location: coordinates } = geometry ?? {};
  const { addressNumber, otherNickname, nickname, mainStreet } = form;
  const { comment, secondaryStreet, reference } = form;
  const { numberContactAddress } = form;
  const enhancedNickname = nickname === "Otro" ? otherNickname : nickname;
  for (const key in form) {
    if (key !== "otherNickname") continue;
    delete form[key];
    break;
  }

  return {
    ...form,
    ...coordinates,
    livingPlace: getDefaultLivingPlaceHelper(formatted_address, comment),
    country: {
      id: country.id,
      name: country.name
    },
    number: addressNumber,
    nickname: enhancedNickname!,
    mainStreet,
    numberContactAddress: `${numberContactAddress}`,
    secondaryStreet: secondaryStreet ?? "",
    reference: reference ?? "",
    validated: true
  };
};

export const getSelectedPutPayload = (
  form: AddressFormValues,
  mapAddress: Google.Geocode,
  country: Country,
  initialValues: AddressFormValues,
  editAddress: ShippingAddress
): PutShippingAddressPayload => {
  const { formatted_address, geometry } = mapAddress ?? {};
  const { livingPlace } = editAddress ?? {};
  const { location: coordinates } = geometry ?? {};
  const { addressNumber, otherNickname, nickname, mainStreet } = form;
  const { comment, secondaryStreet, reference } = form;
  const enhancedNickname = nickname === "Otro" ? otherNickname : nickname;
  for (const key in form) {
    if (key !== "otherNickname") continue;
    delete form[key];
    break;
  }

  return {
    ...form,
    ...coordinates,
    livingPlace: mapAddress
      ? getDefaultLivingPlaceHelper(formatted_address, comment)
      : getFormatLivingPlaceHelper(livingPlace, comment),
    country: {
      id: country.id,
      name: country.name
    },
    number: addressNumber,
    nickname: enhancedNickname!,
    mainStreet,
    secondaryStreet: secondaryStreet ?? "",
    reference: reference ?? "",
    id: initialValues.id!,
    validated: true
  };
};

export const getDefaultNickname = (nickname?: string) => {
  if (!nickname) return "Casa";
  if (
    nickname !== "Casa" &&
    nickname !== "HOME" &&
    nickname !== "Trabajo" &&
    nickname !== "WORK"
  ) {
    return "Otro";
  }
  return nickname;
};

/**
 * Helper to get address comment.
 *
 * @since 1.0.0
 * @param {AddressFormValues} address Address data.
 * @returns Address comment.
 */
export const getCommentHelper = (address?: AddressFormValues) => {
  const { livingPlace, comment } = address ?? {};
  const { fields } = livingPlace ?? {};
  const fieldComment = fields?.find(field => field.id === "comment");
  const { value = "" } = fieldComment ?? {};
  return value ? value : comment;
};
