import { getMaxHeight } from "@sushicorp/utils";
import styled from "styled-components";

import { ChooseAddressInMapStyledProps as Props } from "./ChooseAddressInMap.types";

const ChooseAddressInMapStyled = styled.div<Props>`
  .ChooseAddressInMap {
    &__map {
      height: calc(min(${getMaxHeight()}, 100rem) - 13.2rem);
    }
  }
`;

export default ChooseAddressInMapStyled;
