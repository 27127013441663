import { Modal } from "artisn-ui-react";
import React from "react";

import { backdropConfig } from "./OutOfServiceModal.helpers";
import Styles from "./OutOfServiceModal.styles";
import { OutOfServiceModalProps as Props } from "./OutOfServiceModal.types";

import WifiSVG from "../../../../public/assets/images/wifi-off.svg";

const OutOfServiceModal: React.FC<Props> = props => {
  return (
    <Modal
      backdropConfig={backdropConfig}
      className="OutOfServiceModal__modal"
      opened={true}
    >
      <Styles className="OutOfServiceModal">
        <div className="OutOfServiceModal__svg">
          <WifiSVG />
        </div>
        <h1 className="OutOfServiceModal__title">Fuera de servicio</h1>
        <p className="OutOfServiceModal__description">
          Actualmente la web se encuentra fuera de servicio, intenta volver
          luego o visita nuestros canales oficiales.
        </p>
      </Styles>
    </Modal>
  );
};

OutOfServiceModal.defaultProps = {};

export default OutOfServiceModal;
