import { Providers } from "artisn/analytics";
import { initializeApp } from "firebase/app";
import { getAuth as firebaseGetAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

export const getENVs = (() => {
  const env = process.env.NEXT_PUBLIC_ENV ?? "develop";

  return {
    develop: {
      apiKey: "AIzaSyAxGCMlMcIFlH5PGnFus-pcqXcxP117tyQ",
      authDomain: "sc-kobe-dev.firebaseapp.com",
      databaseURL: "https://sc-kobe-dev-default-rtdb.firebaseio.com",
      projectId: "sc-kobe-dev",
      storageBucket: "sc-kobe-dev.appspot.com",
      messagingSenderId: "1042882461178",
      appId: "1:1042882461178:web:3df015d06468c21667b843",
      measurementId: "G-VHCWX5YCXX",
      mapsApiKey: "AIzaSyD-o9htbV86JDu0mMka5nhz3vuYbBR_oAg",
      facebookPixelId: "1335673266974073"
    },
    production: {
      apiKey: "AIzaSyB-vByQlhp1YHO1dqgsZHNz15kreE3FpUU",
      authDomain: "sc-kobe-prod.firebaseapp.com",
      databaseURL: "https://sc-kobe-prod-default-rtdb.firebaseio.com",
      projectId: "sc-kobe-prod",
      storageBucket: "sc-kobe-prod.appspot.com",
      messagingSenderId: "872566218702",
      appId: "1:872566218702:web:88555d4797addb4927559f",
      measurementId: "G-RGWXXEHS65",
      // TODO: replace this later because this credentials is for dev
      mapsApiKey: "AIzaSyCmaa3-YAtF9PXGJxVl9gLe--Mp0TSbGXg",
      facebookPixelId: "251530160662168"
    }
  }[env];
})();

const firebaseConfig = {
  projectId: getENVs?.projectId ?? "",
  apiKey: getENVs?.apiKey ?? "",
  authDomain: `${getENVs?.projectId}.firebaseapp.com`,
  databaseURL: getENVs?.databaseURL,
  appId: getENVs?.appId
};

export const app = initializeApp(firebaseConfig);
export const getAuth = firebaseGetAuth(app);
export const db = getFirestore(app);
export const realtimeDb = getDatabase(app);

export const facebookProvider = new Providers.FacebookPixel({
  name: "facebook",
  trackingId: getENVs?.facebookPixelId ?? "",
  version: "2.0"
});

export const googleProvider = new Providers.GoogleAnalytics({
  name: "google",
  trackingId: getENVs?.measurementId ?? ""
});
