import { useCatalogues, useStores } from "@sushicorp/contexts";
import { useGeo, useShoppingCart } from "@sushicorp/contexts";
import { buildArtisnHeaders } from "@sushicorp/services";
import { Google } from "@sushicorp/types";
import { DELIVERY_CATALOGUE } from "@sushicorp/utils";
import { getShoppingCart } from "artisn/shopping-cart";
import { removeBenefit, validateShoppingCart } from "artisn/shopping-cart";
import { Award, Benefit, ShoppingCart } from "artisn/types";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { slugify } from "voca";

import { applyBenefitHandler } from "./useBenefit.helpers";
import { TriageApplyBenefitConfig } from "./useBenefit.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";
import { createSuccessNotification } from "utils/notifications.utils";
import { getProductsUtility, getStoreListUtility } from "utils/shoppingCart.utils";
import { getBenefitOfShoppingCartUtility } from "utils/shoppingCart.utils";
import { isValidBenefitExpirationDate } from "utils/shoppingCart.utils";
import { removeBenefitsUtility } from "utils/shoppingCart.utils";
import { isValidCatalogueInBenefit } from "utils/shoppingCart.utils";
import { validateApplyBenefitUtility } from "utils/shoppingCart.utils";

const { ARTISN, API } = CONSTANTS;
const { ACCOUNT_ID, SHOPPING_CART_DEFAULT_NAME } = ARTISN;
const { API_URL } = API;

const useBenefit = () => {
  const { replace } = useRouter();
  const { uid, isAnonymous } = useAuth();
  const { selectedCatalogueId: catalogueId } = useCatalogues();
  const { setTemporalBenefit } = useShoppingCart();
  const { selectedStore: store } = useStores();
  const shippingCost = useShippingCost();
  const { selectedLocation } = useGeo();
  const { lat = 0, lng = 0 } = selectedLocation ?? {};
  const { formattedAddress: address = "" } = selectedLocation ?? {};
  const isDelivery = catalogueId === DELIVERY_CATALOGUE.catalogueId;
  const customerId = uid ?? "";

  const location = useMemo(() => {
    const coordinates = { lat, lng };
    return { address, coordinates };
  }, [address, lat, lng]);

  const shoppingCartConfig = useMemo(
    () => ({
      accountId: ACCOUNT_ID,
      customerId,
      shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
      anonymous: !!isAnonymous
    }),
    [customerId, isAnonymous]
  );

  const triageApplyBenefit = useCallback(
    async (config: TriageApplyBenefitConfig) => {
      const { benefit, location, onFinish, product } = config;
      const shoppingCart = await getShoppingCart(shoppingCartConfig);
      const benefitApplied = getBenefitOfShoppingCartUtility(shoppingCart);
      const { type } = benefit;
      const isProductBenefit = type === "PRODUCT";

      if (benefitApplied) {
        await removeBenefitsUtility(
          store!,
          benefitApplied,
          shoppingCart,
          uid ?? "",
          !!isAnonymous
        );
      }

      const commonApplyBenefitConfig = {
        onFinish,
        benefit,
        shoppingCart,
        customerId,
        isAnonymous: !!isAnonymous,
        shippingCost,
        location,
        store
      };

      const productBenefitConfig = {
        ...commonApplyBenefitConfig,
        product,
        store
      };

      const applyBenefitConfig = !isProductBenefit
        ? commonApplyBenefitConfig
        : productBenefitConfig;
      await applyBenefitHandler(applyBenefitConfig);
    },
    [customerId, isAnonymous, shippingCost, shoppingCartConfig, store, uid]
  );

  const applyBenefit = useCallback(
    async (selectedBenefit: Benefit, onFinish?: () => void) => {
      const shoppingCart = await getShoppingCart(shoppingCartConfig);
      const benefitApplied = getBenefitOfShoppingCartUtility(shoppingCart);
      if (!shoppingCart || Object.keys(shoppingCart.stores).length === 0) {
        throw new Error("Es necesario tener un carrito");
      }

      if (
        benefitApplied &&
        benefitApplied.benefitId === selectedBenefit?.benefitId
      ) {
        throw new Error("Este beneficio ya se encuentra aplicado al carrito");
      }

      const { isValid, reason } = isValidCatalogueInBenefit(
        catalogueId,
        selectedBenefit
      );
      if (!isValid) {
        throw new Error(reason);
      }

      const expirationDateValidation =
        isValidBenefitExpirationDate(selectedBenefit);
      const { isValid: isValidExpirationDate } = expirationDateValidation;
      const { reason: reasonExpirationDate } = expirationDateValidation;

      if (!isValidExpirationDate) {
        throw new Error(reasonExpirationDate);
      }

      const isValidApplyBenefit = await validateApplyBenefitUtility(
        selectedBenefit,
        !!isAnonymous,
        uid ?? ""
      );

      if (!isValidApplyBenefit) {
        throw new Error("El cupón a aplicar no es válido");
      }

      const { type, award } = selectedBenefit ?? {};
      const triageConfig = { benefit: selectedBenefit, location, onFinish };

      switch (type) {
        case "ALTER_DELIVERY":
          if (isDelivery) {
            await triageApplyBenefit(triageConfig);
            return;
          }
          throw new Error(
            "Este cupón es válido solo para órdenes de domicilio"
          );
          return;
        case "DISCOUNT_FIXED":
        case "DISCOUNT_PERCENTAGE":
          await triageApplyBenefit(triageConfig);
          return;

        case "PRODUCT": {
          const [productAward] = award as Award[];
          const { productId, productDescription: name } = productAward ?? {};

          if (!productId) {
            throw new Error("Este beneficio no tiene un producto");
          }
          setTemporalBenefit(selectedBenefit);
          onFinish?.();
          replace(`/products/${productId}/${slugify(name)}`);
          break;
        }

        default:
          break;
      }
    },
    [
      shoppingCartConfig,
      catalogueId,
      isAnonymous,
      uid,
      location,
      isDelivery,
      triageApplyBenefit,
      setTemporalBenefit,
      replace
    ]
  );

  const reApplyBenefit = useCallback(
    async (benefit: Benefit | undefined) => {
      try {
        if (!benefit) return;
        await applyBenefit(benefit);
        createSuccessNotification("Cupón reaplicado exitosamente");
      } catch (e) {
        throw new Error(e.message);
      }
    },
    [applyBenefit]
  );

  const removeBenefits = useCallback(
    async (benefit: Benefit | undefined) => {
      if (!benefit || !store) return;
      const shoppingCart = await getShoppingCart(shoppingCartConfig);
      const { benefitId, type } = benefit;

      try {
        if (
          type === "ALTER_DELIVERY" ||
          type === "DISCOUNT_PERCENTAGE" ||
          type === "DISCOUNT_FIXED"
        ) {
          await removeBenefit({
            benefitId,
            shippingCost: shippingCost ?? null,
            apiURL: API_URL,
            accountId: ACCOUNT_ID,
            anonymous: !!isAnonymous,
            customerId
          });
          return;
        }
        if (type === "PRODUCT") {
          const storeList = getStoreListUtility(shoppingCart);
          const productList = getProductsUtility(storeList);

          const productBenefit = productList.find(
            product => product.benefitId === benefitId
          );

          if (!productBenefit) return;
          await removeBenefit({
            apiURL: API_URL,
            accountId: ACCOUNT_ID,
            anonymous: !!isAnonymous,
            customerId,
            benefitId,
            shippingCost: shippingCost ?? null,
            product: productBenefit,
            productConfig: {
              accountId: ACCOUNT_ID,
              anonymous: !!isAnonymous,
              store,
              customerId
            }
          });
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    [customerId, isAnonymous, shippingCost, shoppingCartConfig, store]
  );

  const validateShoppingCartOnRemove = useCallback(
    async (
      cart: ShoppingCart | null | undefined,
      coordinates: Google.Coordinates
    ) => {
      if (!cart) throw new Error("No hay carrito para validar");
      const { lat, lng } = coordinates;

      try {
        const headers = await buildArtisnHeaders();
        const validation = await validateShoppingCart(
          {
            accountId: ACCOUNT_ID,
            anonymous: !!isAnonymous,
            customerId,
            apiURL: API_URL,
            latitude: lat,
            longitude: lng,
            shoppingCartName: SHOPPING_CART_DEFAULT_NAME
          },
          headers
        );
        const { stores: storeAlerts = [] } = validation ?? {};
        const { products: productAlerts = [] } = validation ?? {};
        const hasStoreAlerts = storeAlerts.length > 0;
        const hasProductAlerts = productAlerts.length > 0;
        if (hasStoreAlerts) {
          throw new Error("La nueva ubicación no puede atender tu pedido");
        }
        if (hasProductAlerts) {
          throw new Error(
            "La nueva ubicación no tiene los productos del carrito"
          );
        }
      } catch (e) {
        throw new Error(e.message);
      }
    },
    [customerId, isAnonymous]
  );

  const removeAppliedBenefits = useCallback(
    async (benefit: Benefit | undefined, coordinates: Google.Coordinates) => {
      try {
        const shoppingCart = await getShoppingCart(shoppingCartConfig);
        if (benefit) {
          await removeBenefits(benefit);
        }
        await validateShoppingCartOnRemove(shoppingCart, coordinates);
      } catch (e) {
        console.error(e);
        throw new Error(e.message);
      }
    },
    [shoppingCartConfig, validateShoppingCartOnRemove, removeBenefits]
  );

  return useMemo(
    () => ({
      removeAppliedBenefits,
      reApplyBenefit
    }),
    [reApplyBenefit, removeAppliedBenefits]
  );
};

export default useBenefit;
