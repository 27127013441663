import styled from "styled-components";

import { ProductBasePriceInfoStyledProps as Props } from "./ProductBasePriceInfo.types";

const ProductBasePriceInfoStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  line-height: 2.8rem;
  font-size: 2.4rem;

  .ProductBasePriceInfo {
    &--normal {
      color: var(--palette-black-s0-l10);
      font-weight: 700;
      font-size: ${props => (props.largePrice ? "2rem" : "1.6rem")};
      font-family: inherit;
      margin-bottom: 0.8rem;
      margin-top: 1.6rem;
    }

    &__point {
      width: 0.4rem;
      height: 0.4rem;
      background-color: var(--palette-gray-s0-l80);
      border-radius: 50%;
      margin: 0 0.8rem;
      font-weight: 600;
    }

    &--points {
      color: var(--palette-text-primary);
      font-weight: 600;
    }
  }
`;

export default ProductBasePriceInfoStyled;
