import styled from "styled-components";

import { DropdownOptionsStyledProps as Props } from "./DropdownOptions.types";
import { DropdownOverlayStyledProps as OverlayProps } from "./DropdownOptions.types";

const DropdownOptionsStyled = styled.div<Props>`
  display: flex;
  align-items: center;

  svg {
    path {
      fill: var(--palette-black);
    }
  }

  .DropdownOptions {
  }
`;

export const DropdownOverlayStyled = styled.div<OverlayProps>`
  display: flex;
  flex-direction: column;
  width: max-content;
  border-radius: 0.8rem;
  background: var(--palette-gray-s0-l97);
  position: ${props => (props.isFixed ? "fixed" : "static")};

  .DropdownOverlay {
    &__option {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--palette-gray-s0-l25);
      border: none;
      justify-content: flex-start;

      &--red {
        color: var(--palette-red-s60-l60);
      }
    }

    &__divider {
      align-self: center;
      display: flex;
      width: 80%;
      border-top: 1px solid var(--palette-gray-s0-l25);
      border-radius: 0.4rem;
    }
  }
`;

export default DropdownOptionsStyled;
