import styled from "styled-components";

import { SearchAddressStyledProps as Props } from "./SearchAddress.types";
import { SearchAddressContentStyledProps } from "./SearchAddress.types";

const SearchAddressStyled = styled.div<Props>`
  display: flex;
  padding: 1.6rem;
  padding-top: 0;
  background-color: var(--palette-white);

  .SearchAddress {
    &__search-bar {
      width: 100%;

      .SearchBar {
        &__wrapper,
        &__btn {
          background-color: var(--palette-gray-s0-l97-a10);
          border-radius: 10rem;

          path {
            fill: var(--palette-gray-s0-l25);
          }
        }
      }
    }

    &__loading {
      height: 2rem;
      width: 2rem;
      animation: rotate 0.6s linear infinite;
    }

    &__cancel-button {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  &&& .SearchBar__input {
    padding: 1rem 1.2rem;
    border: none;
    color: var(--palette-gray-s0-l35);
    background-color: var(--palette-gray-s0-l97-a10);

    &:focus {
      box-shadow: none;
    }

    &:hover:not([disabled]) {
      border: none;
    }
  }
`;

export const SearchAddressContentStyled = styled.div<SearchAddressContentStyledProps>`
  position: fixed;
  width: inherit;
  max-height: calc(100vh - 15.6rem);
  overflow: auto;
  padding-bottom: 0.8rem;

  @media (hover: none) {
    width: var(100vw - 3.2rem);
  }

  .SearchAddressContent {
    &__address-info {
      padding: 0.8rem 0;
      cursor: pointer;
      background-color: var(--palette-white);

      .InfoCard {
        &__content {
          display: flex;
          flex-direction: column-reverse;
        }
      }

      &:hover {
        background-color: var(--palette-primary);

        .InfoCard {
          &__content {
            &__value {
              color: var(--palette-white) !important;
            }
          }
        }
      }
    }

    &__empty {
      font-size: 1.6rem;
      padding: 1.6rem 0;
      padding-left: 1.2rem;
    }
  }
`;

export default SearchAddressStyled;
