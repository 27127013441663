import { Switch as SwitchUI } from "artisn-ui-react";
import styled from "styled-components";

import { SwitchStyledProps as Props } from "./Switch.types";

const SwitchStyled = styled(SwitchUI)<Props>`
  && .Switch {
    &__slider {
      background-color: var(--palette-gray-s0-l98);

      &:before {
        background-color: var(--palette-primary);
      }
    }

    &__wrapper {
      border: 1px solid var(--palette-primary);
    }
  }
`;

export default SwitchStyled;
