import styled from "styled-components";

import { ProductBaseInfoStyledProps as Props } from "./ProductBaseInfo.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ProductBaseInfoStyled = styled.div<Props>`
  .ProductBaseInfo {
    &__name {
      font-size: 2rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
      line-height: 2.8rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: ${tablet}px) {
        white-space: normal;
      }
    }
  }
`;

export default ProductBaseInfoStyled;
