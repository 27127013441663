import styled from "styled-components";

import { ProductErrorStyledProps as Props } from "./ProductError.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const ProductErrorStyled = styled.div<Props>`
  grid-area: col;
  position: ${props => (props.isModal ? "absolute" : "static")};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .ProductError {
    &__error-state {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 28rem;
      margin: 0 auto;
      padding-bottom: 8rem;

      @media screen and (max-width: ${mobile}px) {
        padding-bottom: 2.4rem;
      }

      &__icon {
        border-radius: 50%;
        background-color: var(--palette-gray-s0-l98);
        width: 12.8rem;
        height: 12.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__title {
        padding-top: 2rem;
        padding-bottom: 1.2rem;
        font-size: 2.4rem;
      }

      &__description {
        padding-bottom: 4rem;
        font-size: 1.6rem;
        color: var(--palette-gray-s0-l40);
        text-align: center;
        line-height: 2.4rem;
      }

      &__button {
        padding: 1.6rem 6.4rem;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    &__error-state {
      max-width: 40.8rem;

      @media screen and (max-width: ${mobile}px) {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }

      &__button {
        @media screen and (max-width: ${mobile}px) {
          padding: 1.6rem 4rem;
        }
      }
    }
  }
`;

export default ProductErrorStyled;
