import styled from "styled-components";

import { ModifiersGroupStyledProps as Props } from "./ModifiersGroup.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ModifiersGroupStyled = styled.div<Props>`
  color: var(--palette-text-white);
  background-color: var(--palette-gray-s0-l95);

  && .Pocket {
    &__header {
      padding: 1.6rem 2.4rem;
      border-top: 0.1rem solid var(--palette-gray-s0-l80);
      border-bottom: 0.1rem solid var(--palette-gray-s0-l80);
      background-color: var(--palette-white);

      @media (max-width: ${tablet}px) {
        padding: 1.6rem;
      }

      &__title {
        flex: 1;
        justify-content: space-between;
      }

      & svg {
        display: none;
      }
    }

    &__content > *:not(.ModifiersGroup__divider) {
      padding: 1.6rem 2.4rem;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l80);
      background-color: var(--palette-gray-s0-l97);

      @media (max-width: ${tablet}px) {
        padding: 1.6rem;
      }
    }

    &__content {
      .CheckboxModifier:nth-last-child(1),
      .RadioButtonModifier:nth-last-child(1),
      .CounterModifier:nth-last-child(1) {
        border-bottom: 0;
      }
    }
  }

  &:last-of-type {
    .ModifiersGroup__divider {
      display: none;
    }
  }

  .ModifiersGroup {
    &__pocket-title {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      font-size: 1.6rem;
    }

    &__name {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: var(--palette-black-s0-l10);
      font-weight: 700;
    }

    &__limit {
      background-color: var(--palette-black-s0-l10);
      font-size: 1.2rem;
      color: var(--palette-white);
      width: 4.4rem;
      height: 2.4rem;
      border-radius: 4.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    &__description {
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l35);
      line-height: 1.6rem;
      font-weight: 400;
    }

    &__error {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: var(--palette-red-s60-l60);
    }

    &__required {
      display: flex;
      align-items: center;
      padding: 0 0.8rem;
      color: var(--palette-gray-s0-l80);
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

    &__divider {
      @media (min-width: ${tablet}px) and (max-width: ${tablet}px) {
        margin: 0;
      }
    }
  }
`;

export default ModifiersGroupStyled;
