import React from "react";

import Styles from "./AddNewRecordButton.styles";
import { AddNewRecordButtonProps as Props } from "./AddNewRecordButton.types";

import PlusCircleSVG from "../../../../public/assets/images/plus-circle.svg";

const AddNewRecordButton: React.FC<Props> = props => {
  const { value, mobileValue, icon } = props;
  const addShippingAddressText = mobileValue ?? value;

  return (
    <Styles className="AddNewRecordButton" {...props}>
      {icon ? icon : <PlusCircleSVG />}
      <p className="AddNewRecordButton__value">{value}</p>
      {mobileValue ? (
        <p className="AddNewRecordButton__value AddNewRecordButton__value--mobile">
          {addShippingAddressText}
        </p>
      ) : null}
    </Styles>
  );
};

AddNewRecordButton.defaultProps = {};

export default AddNewRecordButton;
