import styled from "styled-components";

import { BillingDataFoundedModalStyledProps as Props } from "./BillingDataFoundedModal.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const BillingDataFoundedModalStyled = styled.div<Props>`
  width: 60rem;
  padding-inline: 5.2rem;
  padding-block: 4.8rem;

  @media (max-width: ${tablet}px) {
    width: 92vw;
    padding-inline: 2.4rem;
  }

  .BillingDataFoundedModal {
    &__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-block: 2.4rem;
    }

    &__icon {
      background-color: var(--palette-gray-s0-l97);
      border-radius: 100%;
      display: inline-block;
      overflow: hidden;

      & svg {
        & path {
          fill: var(--palette-primary);
        }
      }
    }

    &__header {
      font-weight: 700;
      font-size: 2.6rem;
      line-height: 3.2rem;
      color: var(--palette-black-h60-s25-l2-a10);
      text-align: center;
      padding-block: 0.4rem;

      @media (max-width: ${mobile}px) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }

    &__text {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: var(--palette-gray-s0-l50);
      text-align: center;
      margin-top: 1.2rem;
    }

    &__button {
      width: 100%;
      padding-block: 1.6rem !important;
      margin-top: 1.6rem;

      & .Button {
        &__wrapper {
          font-weight: 600;

          @media (max-width: ${mobile}px) {
            font-size: 1.2rem;
          }
        }
      }

      &:hover:not([disabled]):last-child {
        background-color: unset;
        color: var(--palette-primary);
      }

      @media (max-width: ${mobile}px) {
        margin-top: 1.2rem;
        padding-block: 1.2rem !important;
      }
    }
  }
`;

export default BillingDataFoundedModalStyled;
