import styled from "styled-components";

import { ThirdLevelModifiersStyledProps as Props } from "./ThirdLevelModifiers.types";

const ThirdLevelModifiersStyled = styled.div<Props>`
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;

  .ThirdLevelModifiers {
  }
`;

export default ThirdLevelModifiersStyled;
