import { toSentenceCaseUtility as toSentence } from "@sushicorp/utils";
import { Pocket } from "artisn-ui-react";
import React, { useMemo } from "react";

import { getGroupAmount } from "./ModifiersGroup.helpers";
import Styles from "./ModifiersGroup.styles";
import { ModifiersGroupProps as Props } from "./ModifiersGroup.types";
import CheckboxModifier from "../CheckboxModifier/CheckboxModifier";
import CounterModifier from "../CounterModifier/CounterModifier";
import RadioButtonModifier from "../RadioButtonModifier/RadioButtonModifier";

const ModifiersGroup: React.FC<Props> = props => {
  const { modifierGroup, disabled, preferences } = props;
  const { withPocket = true, className = "" } = props;
  const { name, modifiers, description, errors, data } = modifierGroup;
  const { id: groupId } = modifierGroup;
  const { required } = modifierGroup;
  const { min, max } = data;
  const groupAmount = useMemo(
    () => getGroupAmount(modifierGroup) ?? 0,
    [modifierGroup]
  );
  const disabledGroup = !max ? false : groupAmount >= max;

  const renderTitle = () => {
    return (
      <div className="ModifiersGroup__pocket-title">
        <div className="ModifiersGroup__title">
          <p className="ModifiersGroup__name">{toSentence(name)}</p>
          <p className="ModifiersGroup__description">
            {toSentence(description)}
          </p>
          {errors?.map((error, index) => (
            <p className="ModifiersGroup__error" key={index}>
              {error.code === 202
                ? `Elige al menos ${min} opción/es`
                : `Elige no más de ${max} opción/es`}
            </p>
          ))}
        </div>
        <div className="ModifiersGroup__limit">
          {groupAmount} / {max}
        </div>
        {/* TODO: To comment when it is available in the layout */}
        {/* <div className="ModifiersGroup__required">
          {required ? "Requerido" : "Opcional"}
        </div> */}
      </div>
    );
  };

  const renderModifiers = () => {
    return modifiers.map(modifier => {
      const { type, id } = modifier;

      switch (type) {
        case "RADIO":
          return (
            <RadioButtonModifier
              modifier={modifier}
              preferences={preferences}
              key={id}
              disabled={disabled}
            />
          );
        case "CHECKBOX":
          return (
            <CheckboxModifier
              modifier={modifier}
              key={id}
              disabled={disabled}
              maxDisabled={disabledGroup}
            />
          );
        case "COUNTER":
          return (
            <CounterModifier
              modifier={modifier}
              disabled={disabled}
              key={id}
              maxDisabled={disabledGroup}
            />
          );
        default:
          return (
            <CounterModifier
              disabled={disabled}
              modifier={modifier}
              key={id}
              maxDisabled={disabledGroup}
            />
          );
      }
    });
  };

  return (
    <Styles
      className={`ModifiersGroup ${className}`}
      id={`modifier-group-${groupId}`}
    >
      {withPocket ? (
        <Pocket
          className="ModifiersGroup__pocket"
          title={renderTitle()}
          isOpened={true}
          defaultOpened={required}
        >
          {renderModifiers()}
        </Pocket>
      ) : (
        <>
          {renderTitle()}
          {renderModifiers()}
        </>
      )}
    </Styles>
  );
};

ModifiersGroup.defaultProps = {};

export default ModifiersGroup;
