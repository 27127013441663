import { useShoppingCart as useShoppingCartContext } from "@sushicorp/contexts";
import { useGlobal, useStores } from "@sushicorp/contexts";
import { usePutCancelOrder } from "@sushicorp/services";
import { useFetchIncompleteOrders } from "@sushicorp/services";
import { getFullPath } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { removeBenefit } from "artisn/shopping-cart";
import { ValidateShoppingCartResult } from "artisn/shopping-cart";
import { ShareButton, Clickable } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef, useState } from "react";

import Styles from "./ShoppingCartDrawer.styles";
import { ShoppingCartDrawerProps as Props } from "./ShoppingCartDrawer.types";
import CartPayButton from "components/Cart/CartPayButton/CartPayButton";
import CartProducts from "components/Cart/CartProducts/CartProducts";
import EmptyCart from "components/Cart/EmptyCart/EmptyCart";
import RedeemCoupon from "components/Cart/RedeemCoupon/RedeemCoupon";
import ShoppingCartSummary from "components/checkout/ShoppingCartSummary/ShoppingCartSummary";
import CouponDetail from "components/coupons/CouponDetail/CouponDetail";
import Button from "components/global/Button/Button";
import Divider from "components/global/Divider/Divider";
import ShoppingCartDrawerExtraProducts from "components/global/drawer/ShoppingCartDrawerExtraProducts/ShoppingCartDrawerExtraProducts";
import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import useRequestNavigatorGeo from "hooks/useRequestNavigatorGeo";
import useShippingCost from "hooks/useShippingCost";
import useShoppingCart from "hooks/useShoppingCart/useShoppingCart";
import { notify } from "utils/common.utils";

import CloseSVG from "../../../../../public/assets/images/close.svg";
import CouponSmallSVG from "../../../../../public/assets/images/coupon-small.svg";
import LocationSVG from "../../../../../public/assets/images/location.svg";

const { ARTISN, FEATURE_FLAGS, API } = CONSTANTS;
const { API_URL } = API;
const { SHOPPING_CART_DEFAULT_NAME, ACCOUNT_ID } = ARTISN;
const { logClearCart } = events.shoppingCart;
const { logViewCart } = events.shoppingCart;
const { WITH_COUPONS, WITH_SHARE_SHOPPING_CART } = FEATURE_FLAGS;
const { WITH_EXTRA_PRODUCTS_AS_EXPANDABLE } = FEATURE_FLAGS;

const ShoppingCartDrawer: React.FC<Props> = props => {
  const { onClose, opened } = props;
  const [errors, setErrors] = useState<ValidateShoppingCartResult>();
  const { push } = useRouter();
  const [disabled, setDisabled] = useState(false);
  const t = useI18n();
  const { shoppingCart, setTemporalBenefit } = useShoppingCartContext();
  const { deleteShoppingCart } = useShoppingCart();
  const { analyticsInitialized } = useAnalytics();
  const viewCartLogged = useRef(false);
  const [removeBenefitError, setRemoveBenefitError] = useState("");
  const products = useMemo(() => {
    if (!shoppingCart) return;
    return getShoppingCartProducts(shoppingCart);
  }, [shoppingCart]);
  const { setDrawerConfig } = useGlobal();
  const auth = useAuth();
  const { uid, isAnonymous = false } = auth;
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};
  const { data: incompleteOrders } = useFetchIncompleteOrders(auth, notify);
  const { mutate: cancelOrder } = usePutCancelOrder(auth);
  const empty = (products && products.length === 0) || !shoppingCart;
  const { id: shoppingCartId, benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const shippingCost = useShippingCost();
  const [showCouponInfo, setShowCouponInfo] = useState(isAnonymous);
  useRequestNavigatorGeo();

  const closeHandler = () => {
    onClose();
    setDrawerConfig(p => ({ ...p, show: false }));
  };

  const removeHandler = async () => {
    if (!selectedBenefit) return;
    if (!selectedStore) return;
    if (!uid) return;

    try {
      setRemoveBenefitError("");
      const { benefitId } = selectedBenefit;

      const product = products?.find(
        product => product.benefitId === benefitId
      );

      await removeBenefit({
        benefitId,
        shippingCost,
        product,
        productConfig: {
          store: selectedStore,
          accountId: ACCOUNT_ID,
          customerId: uid,
          anonymous: isAnonymous
        },
        shoppingCartName: SHOPPING_CART_DEFAULT_NAME,
        anonymous: isAnonymous,
        apiURL: API_URL,
        accountId: ACCOUNT_ID,
        customerId: uid
      });
      setTemporalBenefit(undefined);
    } catch (e) {
      setRemoveBenefitError(e.message);
    }
  };

  const emptyCartHandler = () => {
    if (!shoppingCartId) return;
    if (!uid) return;

    logClearCart({
      cartId: shoppingCartId
    });
    deleteShoppingCart?.({ shoppingCartName: SHOPPING_CART_DEFAULT_NAME });
    setTemporalBenefit(undefined);
  };

  useEffect(() => {
    if (!storeId) return;
    if (!products?.length || errors) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [errors, products?.length, storeId]);

  useEffect(() => {
    if (
      !shoppingCartId ||
      viewCartLogged.current ||
      !analyticsInitialized ||
      !products?.length ||
      !opened
    ) {
      return;
    }

    logViewCart({
      cartId: shoppingCartId,
      products: products
    });
    viewCartLogged.current = true;
  }, [analyticsInitialized, products, shoppingCartId, opened]);

  useEffect(() => {
    if (!incompleteOrders || !incompleteOrders.length) return;
    return () => {
      for (const incompleteOrder of incompleteOrders) {
        const { id } = incompleteOrder;
        cancelOrder(id);
      }
    };
  }, [cancelOrder, incompleteOrders]);

  useEffect(() => {
    setShowCouponInfo(isAnonymous);
  }, [isAnonymous]);

  return (
    <Styles
      className="ShoppingCartDrawer"
      opened={opened}
      onClose={closeHandler}
    >
      <div className="ShoppingCartDrawer__head">
        <div className="ShoppingCartDrawer__title">
          {t.cart.cart}
          {WITH_SHARE_SHOPPING_CART && !empty ? (
            <ShareButton
              className="ShoppingCartDrawer__share"
              config={{
                title: `Compartir carrito`,
                text: `Comparte tu carrito con otra persona`,
                url: `${getFullPath()}?shareId=${uid}`
              }}
            />
          ) : null}
        </div>
        <CloseSVG
          className="ShoppingCartDrawer__close"
          onClick={closeHandler}
        />
      </div>
      <div className="ShoppingCartDrawer__store">
        <LocationSVG />
        {selectedStore?.storeName}
      </div>
      {empty ? (
        <>
          <Divider
            className="ShoppingCartDrawer__divider"
            color="dark-light"
            height={0.4}
          />
          <EmptyCart drawerCloseHandler={closeHandler} />
        </>
      ) : (
        <>
          <div className="ShoppingCartDrawer__cart">
            <div>
              {WITH_COUPONS && !isAnonymous ? (
                <RedeemCoupon className="ShoppingCartDrawer__coupon" />
              ) : null}
              {WITH_COUPONS && showCouponInfo ? (
                <>
                  <div className="ShoppingCartDrawer__coupon-anonymous">
                    <div className="ShoppingCartDrawer__coupon-anonymous__icon">
                      <CouponSmallSVG className="ShoppingCartDrawer__coupon-anonymous__svg" />
                    </div>
                    <div
                      className="ShoppingCartDrawer__coupon-anonymous__content"
                      onClick={() => {
                        closeHandler();
                        push("/signin");
                      }}
                    >
                      <p className="ShoppingCartDrawer__coupon-anonymous__title">
                        {t.cart.couponLoginTitle}
                      </p>
                      <p className="ShoppingCartDrawer__coupon-anonymous__description">
                        {t.cart.couponLoginDescription}
                      </p>
                    </div>
                    <Clickable
                      className="ShoppingCartDrawer__coupon-anonymous__close"
                      onClick={() => setShowCouponInfo(false)}
                    >
                      <CloseSVG />
                    </Clickable>
                  </div>
                </>
              ) : null}
              {selectedBenefit ? (
                <CouponDetail
                  inCart
                  removeError={removeBenefitError}
                  benefit={selectedBenefit}
                  onRemove={removeHandler}
                  showRadio={false}
                />
              ) : null}
              <Divider
                className="ShoppingCartDrawer__divider"
                color="dark-light"
                height={0.4}
              />
              <CartProducts disabled={disabled} />
              <Button
                className="ShoppingCartDrawer__empty-button"
                color="white"
                onClick={emptyCartHandler}
              >
                {t.cart.emptyCart}
              </Button>
            </div>
            {!WITH_EXTRA_PRODUCTS_AS_EXPANDABLE ? (
              <div className="ShoppingCartDrawer__extra-products">
                <ShoppingCartDrawerExtraProducts />
              </div>
            ) : null}
            <div>
              <ShoppingCartSummary
                className="ShoppingCartDrawer__summary"
                showTotal={false}
                inCart
              />
            </div>
          </div>
          <div className="ShoppingCartDrawer__pay-button">
            {WITH_EXTRA_PRODUCTS_AS_EXPANDABLE ? (
              <ShoppingCartDrawerExtraProducts />
            ) : null}
            <CartPayButton
              empty={empty}
              setDisabled={setDisabled}
              shoppingCart={shoppingCart}
              setErrors={setErrors}
              isShoppingCartDrawer
            />
          </div>
        </>
      )}
    </Styles>
  );
};

ShoppingCartDrawer.defaultProps = {};

export default ShoppingCartDrawer;
