import styled from "styled-components";

import { CheckboxModifierStyledProps as Props } from "./CheckboxModifier.types";

const CheckboxModifierStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  padding: 1.2rem 0;

  &.CheckboxModifier {
    .Checkbox {
      &__container {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      &__label {
        padding: 0;
      }
    }
  }

  .CheckboxModifier {
    width: 100%;

    &__container {
      display: flex;
      justify-content: space-between;
    }

    &__label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      font-size: 1.4rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export default CheckboxModifierStyled;
