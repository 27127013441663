import React, { useState } from "react";

import Styles from "./ShoppingCartProduct.styles";
import { ShoppingCartProductProps as Props } from "./ShoppingCartProduct.types";
import HorizontalProductCard from "components/global/HorizontalProductCard/HorizontalProductCard";
import useI18n from "hooks/useI18n";

import InfoCircleSVG from "../../../../public/assets/images/info.svg";

const ShoppingCartProduct: React.FC<Props> = props => {
  const {
    product,
    initialQuantity = 0,
    onChangeQuantity,
    onDelete,
    disabled,
    onClick,
    modifiers,
    minCounter = 1,
    onAdd,
    onSubtract,
    ...rest
  } = props;
  const { available } = product ?? {};
  const t = useI18n();
  const [count, setCount] = useState(minCounter);

  const onChange = (value: number) => {
    setCount(value);
    onChangeQuantity(value);
  };

  return (
    <Styles className="ShoppingCartProduct" {...rest} count={count}>
      <HorizontalProductCard
        product={product}
        onClick={onClick}
        modifiers={modifiers}
        enableActions
        onDelete={onDelete}
        showTotal={false}
        counterConfig={{
          className: "ShoppingCartProduct__counter",
          initialValue: initialQuantity,
          onChange,
          onAdd,
          onSubtract,
          disabled,
          min: minCounter
        }}
      />
      <div className="ShoppingCartProduct__modifyProduct">
        {!available ? (
          <p className="ShoppingCartProduct__error">
            <InfoCircleSVG viewBox="0 0 19 20" />
            {t.cart.productNotAvailable}
          </p>
        ) : null}
      </div>
    </Styles>
  );
};

ShoppingCartProduct.defaultProps = {};

export default ShoppingCartProduct;
