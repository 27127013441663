import React, { useState } from "react";

import Styles from "./ThirdLevelModifier.styles";
import { ThirdLevelModifierProps as Props } from "./ThirdLevelModifier.types";
import ModifierModal from "../ModifierModal/ModifierModal";

import ArrowRightSVG from "../../../../../public/assets/images/arrow-right.svg";

const ThirdLevelModifier: React.FC<Props> = props => {
  const { disabled, modifierGroup } = props;
  const { id, name, modifiers, errors, data } = modifierGroup;
  const { min, max } = data;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const selectedModifiers = modifiers
    .filter(modifier => modifier.amount)
    .map(modifier => modifier.name)
    .join(", ");

  const closeModalHandler = () => {
    setIsOpenModal(false);
  };

  const openModalHandler = () => {
    if (disabled) return;
    setIsOpenModal(true);
  };

  return (
    <Styles className="ThirdLevelModifier" disabled={disabled}>
      <div
        className="ThirdLevelModifier__modifier"
        key={id}
        onClick={openModalHandler}
      >
        <p className="ThirdLevelModifier__title">{selectedModifiers || name}</p>
        <ArrowRightSVG />
      </div>
      {errors?.map((error, index) => (
        <p className="ThirdLevelModifier__error" key={index}>
          {error.code === 202
            ? `Elige al menos ${min} opción/es`
            : `Elige no más de ${max} opción/es`}
        </p>
      ))}
      <ModifierModal
        isOpen={isOpenModal}
        onClose={closeModalHandler}
        modifierGroup={modifierGroup}
      />
    </Styles>
  );
};

ThirdLevelModifier.defaultProps = {};

export default ThirdLevelModifier;
