import { useStores } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { Notification } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";

import Styles from "./StoreNotification.styles";
import { StoreNotificationProps as Props } from "./StoreNotification.types";
import ShippingAddressModal from "components/checkout/ShippingAddressModal/ShippingAddressModal";
import ChooseStoreModal from "components/chooseStore/ChooseStoreModal/ChooseStoreModal";
import CONSTANTS from "config/constants";
import { dismissClosedStoreNotification } from "utils/notifications.utils";
import { dismissNoCoverageNotification } from "utils/notifications.utils";
import { createClosedStoreNotification } from "utils/notifications.utils";
import { createNoCoverageNotification } from "utils/notifications.utils";

const { WITH_DELIVERY } = CONSTANTS.FEATURE_FLAGS;

const StoreNotification: React.FC<Props> = props => {
  const { noCoverage, selectedStore, setShowStoreNotification } = useStores();
  const { isOpen } = selectedStore ?? {};
  const isOpenLoading = typeof isOpen === "undefined";
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openChooseStore, setOpenChooseStore] = useState(false);
  const { selectedCatalogue } = useCatalogues();
  const { name } = selectedCatalogue ?? {};
  const selectedCatalogueName = name.toUpperCase();
  const { pathname } = useRouter();
  const pages = ["products", "categories"];
  const allowedToShow = pages.some(page => pathname.includes(page));
  const showStoreNotification =
    !isOpenLoading && !isOpen && !noCoverage && allowedToShow;

  const showStoreModal = useCallback(() => {
    if (selectedCatalogueName === "DELIVERY") {
      setOpenAddressModal(true);
    } else {
      setOpenChooseStore(true);
    }
    setShowStoreNotification(true);
  }, [selectedCatalogueName, setShowStoreNotification]);

  const showAddressModal = useCallback(() => {
    setOpenAddressModal(true);
    setShowStoreNotification(true);
  }, [setShowStoreNotification]);

  const closeNoCoverage = () => {
    dismissNoCoverageNotification();
  };

  const closeStoreNotification = () => {
    dismissClosedStoreNotification();
  };

  useEffect(() => {
    if (noCoverage && allowedToShow && WITH_DELIVERY) {
      createNoCoverageNotification(
        showAddressModal,
        openAddressModal,
        closeNoCoverage
      );
    } else {
      dismissNoCoverageNotification();
    }
    return () => Notification.destroy();
  }, [noCoverage, openAddressModal, allowedToShow, showAddressModal]);

  useEffect(() => {
    if (showStoreNotification) {
      createClosedStoreNotification(
        selectedCatalogueName,
        showStoreModal,
        openChooseStore || openAddressModal,
        closeStoreNotification
      );
    } else {
      dismissClosedStoreNotification();
    }
    return () => Notification.destroy();
  }, [
    showStoreNotification,
    showStoreModal,
    openChooseStore,
    openAddressModal,
    selectedCatalogueName
  ]);

  return (
    <Styles className="StoreNotification">
      <ChooseStoreModal
        opened={openChooseStore}
        onClose={() => {
          setShowStoreNotification(false);
          setOpenChooseStore(false);
        }}
      />
      <ShippingAddressModal
        opened={openAddressModal}
        onClose={() => {
          setShowStoreNotification(false);
          setOpenAddressModal(false);
        }}
      />
    </Styles>
  );
};

StoreNotification.defaultProps = {};

export default StoreNotification;
