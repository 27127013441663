import { useCountries } from "@sushicorp/contexts";
import { toSentenceCaseUtility as toSentence } from "@sushicorp/utils";
import { Button, ProductCard as ProductCardUI } from "artisn-ui-react";
import { useProductCard } from "artisn-ui-react";
import { formatByCurrency } from "artisn-ui-react";
import React from "react";

import Styles from "./HorizontalProductCard.styles";
import { HorizontalProductCardProps as Props } from "./HorizontalProductCard.types";
import Counter from "../Counter/Counter";
import ProductBasePriceInfo from "components/global/ProductBasePriceInfo/ProductBasePriceInfo";
import ProductCardPlaceholder from "components/global/ProductCard/ProductCard.placeholder";
import useDefaultCurrencyFormatter from "hooks/useDefaultCurrencyFormatter";
import useI18n from "hooks/useI18n";

import CrossCircleSVG from "../../../../public/assets/images/cross-circle.svg";
import PencilSVG from "../../../../public/assets/images/pencil.svg";

const { Description, Image, Name } = ProductCardUI;

const HorizontalProductCard: React.FC<Props> = props => {
  const { product, className, onClick, carousel = false, modifiers } = props;
  const { enableActions, onDelete, counterConfig, showTotal = true } = props;
  const t = useI18n();
  const { decimals } = useCountries().selectedCountry;
  const { totals } = useProductCard(product, { decimals });
  const { netPrice } = totals ?? {};
  const defaultFormatter = useDefaultCurrencyFormatter();
  const { onChange, onAdd, onSubtract, disabled } = counterConfig ?? {};
  const { initialValue: initialQuantity } = counterConfig ?? {};
  const { min: minCounter } = counterConfig ?? {};
  const enhancedProduct = carousel
    ? {
        ...product,
        name: toSentence(product.name),
        description: toSentence(product.description),
        images: [product.images[0]]
      }
    : product;

  /**
   * Executed in the event click
   *
   * @since 1.0.0
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event Event data.
   */
  const clickHandler = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!enableActions) return;
    onClick?.(event as React.MouseEvent<HTMLDivElement, MouseEvent>);
  };

  return (
    <Styles className={`HorizontalProductCard ${className}`}>
      <ProductCardUI
        className="HorizontalProductCard__product-details"
        product={enhancedProduct}
        placeholder={<ProductCardPlaceholder />}
      >
        <Image
          className="HorizontalProductCard__image"
          width={100}
          height={100}
          alt={enhancedProduct.name}
          errorImage="/assets/images/fallback-kobe.png"
        />
        <Name className="HorizontalProductCard__name" />
        {modifiers ? (
          <div className="HorizontalProductCard__description">{modifiers}</div>
        ) : (
          <Description className="HorizontalProductCard__description" />
        )}
        <div className="HorizontalProductCard__price-container">
          <ProductBasePriceInfo
            className="HorizontalProductCard__price"
            productTotals={totals}
            showUnits={false}
          />
          {enableActions ? (
            <Button
              className="HorizontalProductCard__btn-delete"
              onClick={onDelete}
            >
              <CrossCircleSVG />
            </Button>
          ) : null}
        </div>
        {showTotal ? (
          <div className="HorizontalProductCard__total">
            {formatByCurrency(netPrice ?? 0, defaultFormatter)}
          </div>
        ) : null}
        {enableActions ? (
          <div className="HorizontalProductCard__controls">
            <div className="HorizontalProductCard__counter">
              <Counter
                initialValue={initialQuantity}
                onChange={onChange}
                onAdd={onAdd}
                onSubtract={onSubtract}
                disabled={disabled}
                min={minCounter}
              />
            </div>
            <div className="HorizontalProductCard__edit">
              <Button
                className="HorizontalProductCard__btn-edit"
                onClick={clickHandler}
              >
                {t.common.edit} <PencilSVG />
              </Button>
            </div>
          </div>
        ) : null}
      </ProductCardUI>
    </Styles>
  );
};

HorizontalProductCard.defaultProps = {
  className: ""
};

export default HorizontalProductCard;
