import { useGeo, useGlobal } from "@sushicorp/contexts";
import { useCountries } from "@sushicorp/contexts";
import { useFetchGoogleAddressFromCoords } from "@sushicorp/services";
import { useFetchNearbyStores } from "@sushicorp/services";
import { Coords } from "google-map-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Styles from "./ChooseAddressInMap.styles";
import { ChooseAddressInMapProps as Props } from "./ChooseAddressInMap.types";
import Map from "components/global/Map/Map";
import SearchAddress from "components/global/SearchAddress/SearchAddress";
import useAuth from "contexts/auth/auth.context.hooks";
import useI18n from "hooks/useI18n";
import useRequestNavigatorGeo from "hooks/useRequestNavigatorGeo";
import { Google } from "types/geo.types";
import { notify } from "utils/common.utils";
import { dismissErrorNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";

const ChooseAddressInMap: React.FC<Props> = props => {
  const { onSelectedAddress, onSelectedCoordinates } = props;
  const { initialCoordinates, socializedAddress } = props;
  const { isAnonymous } = useAuth();
  const { setDrawerConfig } = useGlobal();
  const { selectedCoordinates } = useGeo();
  const t = useI18n();
  useRequestNavigatorGeo();
  const [shippingAddressCoordinates, setShippingAddressCoordinates] =
    useState<Google.Coordinates>();
  const [localSocializedAddress, setLocalSocializedAddress] = useState<
    string | undefined
  >(socializedAddress);
  const [isFromAddressList, setIsFromAddressList] = useState(false);
  const [mapAddress, setMapAddress] = useState<
    Google.ReverseGeocodeAddress | undefined
  >();
  const { formatted_address } = mapAddress ?? {};
  const fetchGoogleAddressFromCoords = useFetchGoogleAddressFromCoords(
    process.env.NEXT_PUBLIC_MAPS_API_KEY ?? "",
    shippingAddressCoordinates ?? selectedCoordinates
  );

  const getAddress = () => {
    if (isFromAddressList) return localSocializedAddress ?? formatted_address;
    return formatted_address;
  };

  const onContentClick = (item: Google.Autocomplete) => {
    const { description } = item;
    setLocalSocializedAddress(description);
    setIsFromAddressList(true);
  };

  const { data: predictions, error, isLoading } = fetchGoogleAddressFromCoords;
  const [selectedAddress, setSelectedAddress] = useState<Google.Geocode>();
  const { data: stores } = useFetchNearbyStores(
    shippingAddressCoordinates ?? selectedCoordinates,
    notify
  );
  const { lat, lng } = useCountries().selectedCountry;
  const defaultCoords = useMemo(() => ({ lat, lng }), [lat, lng]);
  const changeHandler = useCallback((place: Google.Geocode) => {
    const { geometry } = place;
    const { location } = geometry;
    setShippingAddressCoordinates(location);
    setSelectedAddress(place);
  }, []);

  const confirmHandler = (coordinates: Coords) => {
    setDrawerConfig(p => ({ ...p, location: getAddress() ?? "" }));

    if (isAnonymous) {
      onSelectedCoordinates?.(coordinates);
      return;
    }
    if (!selectedAddress) return;
    setShippingAddressCoordinates(coordinates);
    onSelectedAddress?.(selectedAddress, getAddress());
  };

  const changeHandlerMap = (coordinates: Coords) => {
    if (!isFromAddressList) setLocalSocializedAddress(undefined);
    setShippingAddressCoordinates(coordinates);
  };

  useEffect(() => {
    if (predictions && predictions.length > 0) {
      setMapAddress(predictions[0]);
      setSelectedAddress(predictions[0]);
    }
  }, [predictions]);

  useEffect(() => {
    if (!error) return;
    dismissErrorNotification();
    createErrorNotification(t.errors.showMapAddress);
    console.error(error);
  }, [error, t]);

  useEffect(() => {
    setShippingAddressCoordinates(
      isAnonymous ? selectedCoordinates : initialCoordinates
    );
  }, [initialCoordinates, isAnonymous, selectedCoordinates]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.position = "static";
  }, []);

  return (
    <Styles className="ChooseAddressInMap">
      <SearchAddress onChange={changeHandler} onContentClick={onContentClick} />
      <Map
        className="ChooseAddressInMap__map"
        isPinView
        defaultZoom={17}
        center={
          shippingAddressCoordinates ?? selectedCoordinates ?? defaultCoords
        }
        address={getAddress()}
        onConfirmClick={confirmHandler}
        onChange={changeHandlerMap}
        noCoverage={!stores?.length}
        isLoading={isLoading}
        onDragEnd={() => setIsFromAddressList(false)}
      />
    </Styles>
  );
};

ChooseAddressInMap.defaultProps = {};

export default ChooseAddressInMap;
