import { Drawer } from "artisn-ui-react";
import styled from "styled-components";

import { ShoppingCartDrawerStyledProps as Props } from "./ShoppingCartDrawer.types";
import CONSTANTS from "config/constants";

const { mobile, tablet, desktop } = CONSTANTS.BREAKPOINTS;

const ShoppingCartDrawerStyled = styled(Drawer)<Props>`
  &.ShoppingCartDrawer {
    background-color: var(--palette-gray-s0-l97-a10);
    padding-top: 2.4rem;
    width: 100%;
    min-height: var(--sizes-page-maxHeight);
    max-height: var(--sizes-page-maxHeight);
    display: flex;
    flex-direction: column;

    @media (min-width: ${tablet + 1}px) {
      width: 44rem;
    }

    @media (min-width: ${desktop + 1}px) {
      min-width: 66.6rem;
    }
  }

  .ShoppingCartDrawer {
    &__extra-products {
      padding-inline: 0.8rem;
      padding-top: 1.6rem;
    }

    &__store {
      display: flex;
      align-items: center;
      padding-bottom: 3.2rem;
      font-weight: 700;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      color: var(--palette-black-s0-l10);
      font-size: 1.6rem;

      svg {
        margin-right: 0.8rem;
      }
    }

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3.6rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    &__close {
      cursor: pointer;
      z-index: 2;
    }

    &__share {
      margin-left: 2rem;
    }

    &__title {
      font-size: 2rem;
      line-height: 2.8rem;
      color: var(--palette-black-s0-l10);
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    &__cart {
      overflow-y: auto;
      scrollbar-width: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20rem;

      .CartProducts {
        @media (min-width: ${tablet}px) {
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }
      }

      .ShoppingCartDrawer {
        &__summary {
          margin: 1.6rem 1.6rem 3.2rem 1.6rem;
        }
      }

      .CouponDetail {
        @media (min-width: ${mobile}px) {
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }
      }
    }

    &__cart::-webkit-scrollbar {
      width: 0;
    }

    &__coupon {
      padding-bottom: 2rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;

      .RedeemCoupon {
        &__badge {
          flex: 1;
        }

        &__button-wallet {
          width: 100%;
        }

        &__container {
          input.TextInput__input {
            height: auto;
            background-color: var(--palette-white);
            border: none;
            padding: 1.2rem 10.8rem 1.2rem 1.6rem;
            color: var(--palette-black-s0-l10);
            font-size: 1.6rem;
            font-weight: 700;
          }

          button.RedeemCouponInput__input-button {
            position: absolute;
            top: 3.4rem;
            right: 1.2rem;
            height: 3.6rem;
            padding: 1.6rem 1.2rem;
            font-weight: 600;
            z-index: 1;
            font-size: 1.6rem;
          }
        }
      }
    }

    &__coupon-anonymous {
      background-color: var(--palette-white);
      margin-left: 1.6rem;
      margin-right: 1.6rem;
      margin-bottom: 1.6rem;
      padding: 1.6rem;
      border-radius: 0.8rem;
      position: relative;
      display: grid;
      align-items: flex-start;
      gap: 0.8rem;
      grid-template: "icon content close";

      &__content {
        grid-area: content;

        &:hover {
          cursor: pointer;
        }
      }

      &__title {
        color: var(--palette-black-s0-l10);
        font-weight: 700;
        font-size: 1.6rem;
        margin-bottom: 0.4rem;
      }

      &__description {
        font-size: 1.2rem;
        color: var(--palette-gray-s0-l61);
      }

      &__close {
        grid-area: close;
        padding: 0;
      }

      &__icon {
        background-color: var(--palette-primary);
        border-radius: 0.4rem;
        padding: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: ${mobile}px) {
          padding: 0.8rem;
        }
      }
    }

    &__divider {
      margin-bottom: 1.6rem;

      @media (max-width: ${tablet}px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &__order {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l35);
      line-height: 1.6rem;
      padding: 0.8rem 2rem;
    }

    &__empty-button.Button {
      font-size: 1.6rem;
      line-height: 1.6rem;
      margin-top: 1.6rem;
      margin-left: 1.6rem;
      font-weight: 700;
      padding: 1.2rem 2.4rem;
      background-color: var(--palette-white);
      color: var(--palette-primary);
      border: none;

      &:hover {
        background-color: var(--palette-white) !important;
        color: var(--palette-primary) !important;
        border: none !important;
      }
    }

    &__add-button {
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 600;
      justify-content: flex-start;
      padding: 1.2rem 2.4rem;
      margin: 2rem 0;

      @media (max-width: ${mobile}px) {
        padding: 1.2rem;
        width: 100%;
      }

      svg {
        margin-right: 0.8rem;

        path {
          fill: var(--palette-primary);
        }
      }
    }

    &__summary {
      margin: 7.2rem 1.6rem 3.2rem 1.6rem;
      background-color: var(--palette-white);
      padding: 1.6rem;
      border-radius: 0.4rem;

      .ShoppingCartSummary__title {
        display: none;
      }

      .ShoppingCartItem,
      .ShoppingCartItem:last-child {
        font-family: inherit;
        font-size: 1.4rem;
        color: var(--palette-black-s0-l10);
        font-weight: 700;
      }
    }

    &__pay-button {
      background-color: var(--palette-white);
      width: 100%;
      display: flex;
      position: sticky;
      bottom: 0;
      left: 0;
      justify-content: center;
      padding-top: 1.6rem;
      padding-inline: 1.6rem;
      padding-bottom: calc(1.6rem + env(safe-area-inset-bottom));
      box-shadow: 0px 0px 10px var(--palette-gray-s0-l85);
      z-index: 1;
      border-top-left-radius: 2.4rem;
      border-top-right-radius: 2.4rem;

      .CartPayButton,
      .Cart__summary__button {
        width: 100%;
      }
    }
  }

  .EmptyCart {
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default ShoppingCartDrawerStyled;
