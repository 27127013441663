import { ShoppingCartSummary } from "artisn-ui-react";
import styled from "styled-components";

import { ShoppingCartSummaryStyledProps as Props } from "./ShoppingCartSummary.types";

const ShoppingCartSummaryStyled = styled(ShoppingCartSummary)<Props>`
  padding-left: 0rem;
  padding-right: 0rem;
  margin-bottom: 0;

  .ShoppingCartSummary {
    &Subtotal > * {
      font-size: 1.4rem;
      font-weight: 700;
      color: var(--palette-black-s0-l10);
    }

    &Discount {
      & > * {
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--palette-black-s0-l10);
      }

      &__value {
        color: var(--palette-red-s60-l60);
      }
    }

    &Taxes > * {
      font-size: 1.4rem;
      font-weight: 700;
      color: var(--palette-black-s0-l10);
    }

    &ShippingCost > * {
      font-size: 1.4rem;
      font-weight: 700;
      color: var(--palette-black-s0-l10);
    }

    &Total > * {
      font-size: 1.4rem;
      font-weight: 700;
      color: var(--palette-black-s0-l10);
    }

    &__package-cost {
      &__content {
        display: flex;
        justify-content: space-between;
      }

      &__title,
      &__value {
        color: var(--palette-black-s0-l10);
        font-size: 1.4rem;
        font-weight: 700;
        padding-block: 0.6rem;
      }

      &__info {
        color: var(--palette-gray-s0-l80) !important;
        font-size: 1.2rem !important;
        font-weight: 400 !important;
        text-align: end !important;
        padding-top: 1.4rem;
      }
    }
  }
`;

export default ShoppingCartSummaryStyled;
