import styled from "styled-components";

import { ShoppingCartDrawerExtraProductsStyledProps as Props } from "./ShoppingCartDrawerExtraProducts.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const ShoppingCartDrawerExtraProductsStyled = styled.div<Props>`
  && .Pocket {
    &__header {
      margin: 0rem 1.6rem;
      margin-bottom: 2rem;
      margin-top: 1.2rem;
      padding-bottom: 1.2rem;
      border-bottom: 0.1rem solid var(--palette-gray-s0-l95);
      border-top-left-radius: 2.4rem;
      border-top-right-radius: 2.4rem;
      cursor: auto;

      &__title {
        flex: 1;
        justify-content: space-between;
      }
    }

    &__content > *:not(.ExtraProducts__divider) {
      padding: 0rem 1.6rem;
      padding-bottom: 2rem;
    }
  }

  .ShoppingCartDrawerExtraProducts {
    &__pocket {
      & .Pocket__header {
        & > svg {
          display: none;
        }
      }
    }

    &__pocket-title {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      @media (max-width: ${mobile + 1}px) {
        width: 90%;
      }
    }

    &__name {
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: 700;
      color: var(--palette-black);
    }

    &__description {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 1.2rem;
      color: var(--palette-gray-s0-l35);
      font-weight: 400;
      line-height: 1.6rem;

      @media (max-width: ${mobile}px) {
        font-size: 1.1rem;
        padding-right: 0.4rem;
      }
    }

    &__extra-info {
      font-size: 1.1rem;
      color: var(--palette-gray-s0-l35);
      font-weight: 400;
      margin-bottom: 2rem;

      &--link {
        border: none;
        background-color: unset;
        font-weight: 700;
        margin-left: 0.4rem;
        border-bottom: 0.1rem solid var(--palette-gray-s0-l35);
        cursor: pointer;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.8rem;
      transform: ${props =>
        !props.isOpened ? "rotate(90deg)" : "rotate(270deg)"};
      cursor: pointer;

      @media (max-width: ${mobile + 1}px) {
        flex: 1;
      }

      svg {
        path {
          fill: var(--palette-gray-s0-l25);
        }
      }
    }

    &__product {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--name {
        font-size: 1.4rem;
        font-weight: 700;
        color: var(--palette-black);
      }

      &--description {
        font-size: 1rem;
        color: var(--palette-gray-s0-l70);
        line-height: 1.6rem;
        font-weight: 400;
      }
    }

    &__counter {
      .Counter {
        background-color: var(--palette-gray-s0-l95);

        &__quantity {
          background-color: var(--palette-gray-s0-l95);
          font-size: 1.2rem;
        }

        &__button {
          background-color: var(--palette-gray-s0-l95);

          &:disabled {
            path {
              fill: var(--palette-gray-s0-l77);
            }
          }
        }
      }
    }
  }
`;

export default ShoppingCartDrawerExtraProductsStyled;
