import { getMaxWidth } from "@sushicorp/utils";
import styled from "styled-components";

import { NotificationStyledProps as Props } from "./Notification.types";
import CONSTANTS from "config/constants";

const { mobile } = CONSTANTS.BREAKPOINTS;

const NotificationStyled = styled.div<Props>`
  width: clamp(30.4rem, 40rem, calc(${getMaxWidth()} - 1.2rem));
  box-shadow: 0 0.4rem 1.6rem var(--palette-gray-s0-l20-a08),
    0 0.4rem 0.4rem var(--palette-gray-s0-l20-a04),
    inset 0 0 0 0.2rem var(--palette-gray-s0-l95);
  padding: 2rem;
  border-radius: 0.8rem;
  max-width: 39.2rem;
  background-color: var(--palette-gray-s0-l98);
  position: relative;

  .Notification {
    &__close {
      position: absolute;
      right: 1.6rem;
      top: 2.4rem;
      cursor: pointer;

      @media (max-width: ${mobile}px) {
        right: 1.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

export default NotificationStyled;
