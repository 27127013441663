import { useExtraProducts, useUtensils } from "@sushicorp/contexts";
import { useShoppingCart } from "@sushicorp/contexts";
import { Pocket } from "artisn-ui-react";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { forwardRef, ForwardRefRenderFunction } from "react";

import Styles from "./ShoppingCartDrawerExtraProducts.styles";
import { ShoppingCartDrawerExtraProductsProps as Props } from "./ShoppingCartDrawerExtraProducts.types";
import { ShoppingCartDrawerExtraProduct } from "./ShoppingCartDrawerExtraProducts.types";
import { ShoppingCartDrawerExtraProductsMethods } from "./ShoppingCartDrawerExtraProducts.types";
import Counter from "components/global/Counter/Counter";
import Switch from "components/global/Switch/Switch";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";

import ChevronLeftSVG from "../../../../../public/assets/images/chevron-left-black.svg";

const { WITH_EXTRA_PRODUCTS_AS_EXPANDABLE } = CONSTANTS.FEATURE_FLAGS;

const ShoppingCartDrawerExtraProducts: ForwardRefRenderFunction<
  ShoppingCartDrawerExtraProductsMethods,
  Props
> = (_props, ref) => {
  const t = useI18n();
  const { setExtraProducts, extraProducts } = useExtraProducts();
  const [isOpened, setIsOpened] = useState(true);
  const { setWithUtensils } = useUtensils();
  const { push } = useRouter();
  const { setOpenShoppingCartDrawer } = useShoppingCart();
  const extrasCategoryId =
    process.env.NEXT_PUBLIC_ENV === "production" ? 5310 : 1595;

  /**
   * Function to counter change handler
   *
   * @since 1.0.0
   * @param {number} amount Amount to change in counter.
   * @param {string} code Code of the extra product.
   */
  const counterChangeHandler = (amount: number, code: string) => {
    const newItems = extraProducts?.map(extra => {
      if (extra.code === code) extra.value = amount;
      return extra;
    });

    setExtraProducts(newItems);
  };

  /**
   * Function to handle switch change.
   *
   * @since 1.0.0
   */
  const handleSwitchChange = () => {
    setIsOpened(prev => !prev);
    setWithUtensils(prev => !prev);
  };

  /**
   * Function to handle press extra category.
   *
   * @since 1.0.0
   */
  const handlePressExtraCategory = () => {
    push(`/categories/${extrasCategoryId}/extras`);
    setOpenShoppingCartDrawer(prev => !prev);
  };

  /**
   * Function to render title.
   *
   * @since 1.0.0
   */
  const renderTitle = () => {
    return (
      <div className="ShoppingCartDrawerExtraProducts__pocket-title">
        <div className="ShoppingCartDrawerExtraProducts__title">
          <p className="ShoppingCartDrawerExtraProducts__name">
            {t.productDetail.utensils}
          </p>
          {WITH_EXTRA_PRODUCTS_AS_EXPANDABLE ? (
            <p className="ShoppingCartDrawerExtraProducts__description">
              {extraProducts?.map(
                (extra: ShoppingCartDrawerExtraProduct, index) => {
                  return `${extra.value} ${extra.name} ${
                    index !== extraProducts.length - 1 ? "· " : ""
                  }`;
                }
              )}
            </p>
          ) : null}
        </div>
        {WITH_EXTRA_PRODUCTS_AS_EXPANDABLE ? (
          <div
            className="ShoppingCartDrawerExtraProducts__icon"
            onClick={() => setIsOpened(prev => !prev)}
          >
            <ChevronLeftSVG />
          </div>
        ) : (
          <Switch
            checked={isOpened}
            onChange={handleSwitchChange}
            name="toggleExtraProducts"
          />
        )}
      </div>
    );
  };

  return (
    <Styles className="ShoppingCartDrawerExtraProducts" isOpened={isOpened}>
      <Pocket
        title={renderTitle()}
        className="ShoppingCartDrawerExtraProducts__pocket"
        isOpened={isOpened}
      >
        {extraProducts && extraProducts?.length > 0
          ? extraProducts.map(item => {
              const { value, code, name, maxValue } = item;
              const extraName = name.toLocaleLowerCase().includes("salsa")
                ? t.shoppingCart.sauce
                : t.shoppingCart.utensil;
              return (
                <div
                  className="ShoppingCartDrawerExtraProducts__product"
                  key={code}
                >
                  <div className="ShoppingCartDrawerExtraProducts__product--right-content">
                    <p className="ShoppingCartDrawerExtraProducts__product--name">
                      {name}
                    </p>
                    <p className="ShoppingCartDrawerExtraProducts__product--description">
                      {`${t.shoppingCart.maximum} ${maxValue} ${
                        maxValue === 1 ? extraName : extraName.concat("s")
                      }`}
                    </p>
                  </div>
                  <Counter
                    className="ShoppingCartDrawerExtraProducts__counter"
                    initialValue={value}
                    max={maxValue}
                    min={0}
                    onChange={amount => counterChangeHandler(amount, code)}
                  />
                </div>
              );
            })
          : null}
        <p className="ShoppingCartDrawerExtraProducts__extra-info">
          {t.shoppingCart.findExtraProducts}
          <button
            className="ShoppingCartDrawerExtraProducts__extra-info ShoppingCartDrawerExtraProducts__extra-info--link"
            onClick={handlePressExtraCategory}
          >
            {t.shoppingCart.extras}
          </button>
        </p>
      </Pocket>
    </Styles>
  );
};

export default forwardRef<ShoppingCartDrawerExtraProductsMethods, Props>(
  ShoppingCartDrawerExtraProducts
);
