import { BillingDataProvider, GeoProvider } from "@sushicorp/contexts";
import { GlobalProvider } from "@sushicorp/contexts";
import { CountriesProvider, VendorsProvider } from "@sushicorp/contexts";
import { CataloguesProvider, ShoppingCartProvider } from "@sushicorp/contexts";
import { PaymentsProvider, StoresProvider } from "@sushicorp/contexts";
import { ProductsProvider } from "@sushicorp/contexts";
import { ShippingAddressProvider } from "@sushicorp/contexts";
import { ShoppingCartNotificationsProvider } from "@sushicorp/contexts";
import { PackageCostProvider, UtensilsProvider } from "@sushicorp/contexts";
import React from "react";

import { ArtisnProviderProps as Props } from "./ArtisnProvider.types";
import AnalyticsProvider from "contexts/analytics/analytics.context";
import AuthProvider from "contexts/auth/auth.context";
import OrdersProvider from "contexts/orders/orders.context";
import { TalkShopProvider } from "contexts/talkShop/talkShop.context";

const ArtisnProvider: React.FC<Props> = props => {
  const { children } = props;

  return (
    <AnalyticsProvider>
      <GlobalProvider>
        <AuthProvider>
          <OrdersProvider>
            <CountriesProvider>
              <VendorsProvider projectAccountId={29}>
                <PaymentsProvider>
                  <PackageCostProvider>
                    <UtensilsProvider>
                      <BillingDataProvider>
                        <StoresProvider>
                          <ProductsProvider>
                            <GeoProvider>
                              <ShippingAddressProvider>
                                <ShoppingCartProvider>
                                  <CataloguesProvider>
                                    <ShoppingCartNotificationsProvider>
                                      <TalkShopProvider>
                                        {children}
                                      </TalkShopProvider>
                                    </ShoppingCartNotificationsProvider>
                                  </CataloguesProvider>
                                </ShoppingCartProvider>
                              </ShippingAddressProvider>
                            </GeoProvider>
                          </ProductsProvider>
                        </StoresProvider>
                      </BillingDataProvider>
                    </UtensilsProvider>
                  </PackageCostProvider>
                </PaymentsProvider>
              </VendorsProvider>
            </CountriesProvider>
          </OrdersProvider>
        </AuthProvider>
      </GlobalProvider>
    </AnalyticsProvider>
  );
};

ArtisnProvider.defaultProps = {};

export default ArtisnProvider;
