import { getMaxHeight, getMaxWidth } from "@sushicorp/utils";
import { Modal } from "artisn-ui-react";
import styled, { css } from "styled-components";

import { ModalStyledProps as Props } from "./Modal.types";

const ModalStyled = styled(Modal)<Props>`
  ${props =>
    !props.noDefaultDimensions
      ? css`
          width: min(${getMaxWidth()}, 60rem);
          height: min(${getMaxHeight()}, 100rem);

          @media all and (hover: none) {
            width: ${getMaxWidth()};
            height: ${getMaxHeight()};
          }
        `
      : ""}
  border-radius: 1.2rem;

  .Modal {
    border-radius: 1.2rem !important;

    &__body {
      background-color: var(--palette-white);
    }
  }
`;

export default ModalStyled;
