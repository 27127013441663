// useShippingCost custom hook

import { useGeo } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { useFetchShippingCost } from "@sushicorp/services";
import { useMemo } from "react";

import { notify } from "utils/common.utils";

const useShippingCost = () => {
  const { catalogueId } = useCatalogues().selectedCatalogue;
  const { selectedStore } = useStores();
  const { storeId } = selectedStore ?? {};
  const { selectedCoordinates } = useGeo();
  const { lat, lng } = selectedCoordinates ?? {};

  const { data: shippingCost } = useFetchShippingCost(
    {
      storeId,
      catalogueId,
      lat,
      lng
    },
    notify
  );

  return useMemo(() => {
    return shippingCost ? { ...shippingCost, discounts: [] } : null;
  }, [shippingCost]);
};

export default useShippingCost;
