import styled from "styled-components";

import { ProductStyledProps as Props } from "./Product.types";
import { ProductPlaceholderStyledProps as PlaceholderProps } from "./Product.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile, desktop } = BREAKPOINTS;

const ProductStyled = styled.div<Props>`
  min-width: var(--sizes-page-minWidth);
  max-width: var(--sizes-page-maxWidth);
  min-height: var(--sizes-page-minHeight);
  padding-top: calc(var(--sizes-navbar-height) - env(safe-area-inset-top));
  background-color: var(--palette-white);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas:
    ". header ."
    ".      main   ."
    "footer footer footer";

  @media (max-width: ${desktop}px) {
    padding-top: var(--sizes-navbar-height-tablet);
  }

  @media (max-width: ${mobile}px) {
    padding-top: var(--sizes-navbar-height-mobile);
  }

  & .Pocket {
    &__header {
      padding: 0.8rem;
      grid-area: header;

      &__title {
        font-size: 1.6rem;
        color: var(--palette-black-s0-l10);
      }
    }
  }

  .Product {
    &__header {
      .Product__shareButton {
        position: relative;
        top: -0.1rem;

        .Clickable {
          padding: 0;
        }
      }
    }

    &__navbar {
      @media (max-width: ${tablet}px) {
        display: grid;
      }
    }

    &__main {
      grid-area: main;

      &__header {
        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__topActions {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 2.4rem 0;

      @media (max-width: 851px) {
        width: 100%;
      }

      .Product__shareButton {
        display: block;

        .Clickable {
          padding-top: 0;
          padding-bottom: 0;
        }

        & .ProductMain__shareButton-button {
          &__content {
            display: flex;
            align-items: center;
          }

          &__text {
            margin-left: 1.6rem;
            color: var(--palette-primary);
            font-weight: 600;
            font-size: 1.4rem;
          }
        }

        @media (max-width: ${tablet}px) {
          display: none;
        }
      }
    }

    &__shareButton {
      display: flex;
      justify-content: flex-end;

      @media (min-width: ${tablet + 1}px) {
        display: none;
      }
    }

    &__gallery {
      padding-bottom: 1.6rem;
      border-bottom: 0.8rem solid var(--palette-gray-s0-l98);
      margin-bottom: 1.6rem;

      @media (min-width: ${tablet}px) {
        display: none;
      }
    }

    &__slider {
      padding-bottom: 14rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__wishlist-button {
      margin-left: auto;

      path {
        fill: var(--palette-primary);
      }
    }

    &__category-gallery {
      padding-bottom: 12rem;

      @media (max-width: ${tablet}px) {
        padding-bottom: 2.4rem;
      }
    }

    &__footer {
      grid-area: footer;

      @media (hover: none) {
        margin-bottom: 7.4rem;
      }
    }
  }

  && .ProductBaseInfo {
    &__name {
      white-space: normal;
    }
  }
`;

export const ProductPlaceholderStyled = styled.div<PlaceholderProps>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  grid-area: col;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
    align-items: center;
  }

  .ProductPlaceholder {
    &__image-container {
      display: flex;
      justify-content: center;
      flex: 1 0 0;
      display: flex;

      @media (max-width: ${tablet}px) {
        width: auto;
        min-width: 0;
      }
    }

    &__image {
      width: 100%;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      width: 40rem;
      height: 40rem;

      @media (max-width: ${tablet}px) {
        width: 36rem;
        height: 36rem;
      }

      @media (max-width: ${mobile}px) {
        width: 28rem;
        height: 28rem;
      }
    }

    &__form {
      flex: 1 0.3 0;
      padding-left: 3.2rem;

      @media (max-width: ${tablet}px) {
        width: auto;
        min-width: 32rem;
        padding-top: 3.2rem;
        padding-left: 0;
      }

      @media (max-width: ${mobile}px) {
        min-width: 28rem;
      }
    }

    &__name {
      width: 90%;
      height: 2.4rem;
      background-color: var(--palette-gray-s0-l95);
      margin-bottom: 1.6rem;
      border-radius: 0.4rem;
    }

    &__price {
      width: 50%;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l95);
      margin-bottom: 1.6rem;
      border-radius: 0.4rem;
    }

    &__description {
      width: 80%;
      height: 5rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__actions {
      margin-top: 1.6rem;
      display: flex;
      align-items: center;
    }

    &__counter {
      width: 15%;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
      margin-right: 3.2rem;
    }

    &__button {
      width: 40%;
      height: 4rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__modifierGroup {
      width: 100%;
      margin-top: 3.2rem;
      display: flex;
      flex-direction: column;

      &-name {
        width: 60%;
        height: 2.4rem;
        background-color: var(--palette-gray-s0-l95);
        margin-bottom: 1.6rem;
        border-radius: 0.4rem;
      }

      &-description {
        width: 40%;
        height: 2rem;
        background-color: var(--palette-gray-s0-l95);
        margin-bottom: 3.2rem;
        border-radius: 0.4rem;
      }
    }

    &__modifier {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      align-items: center;
    }

    &__modifier-field {
      width: 10%;
      height: 2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__modifier-name {
      width: 40%;
      height: 2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }

    &__modifier-price {
      width: 20%;
      height: 3.2rem;
      background-color: var(--palette-gray-s0-l95);
      border-radius: 0.4rem;
    }
  }
`;

export default ProductStyled;
