import React from "react";

import Styles from "./ThirdLevelModifiers.styles";
import { ThirdLevelModifiersProps as Props } from "./ThirdLevelModifiers.types";
import ThirdLevelModifier from "../ThirdLevelModifier/ThirdLevelModifier";

const ThirdLevelModifiers: React.FC<Props> = props => {
  const { className, modifierGroups, disabled } = props;

  return (
    <Styles className={`ThirdLevelModifiers ${className}`}>
      {modifierGroups.map(modifierGroup => {
        const { id } = modifierGroup;

        return (
          <ThirdLevelModifier
            key={id}
            disabled={disabled}
            modifierGroup={modifierGroup}
          />
        );
      })}
    </Styles>
  );
};

ThirdLevelModifiers.defaultProps = { className: "" };

export default ThirdLevelModifiers;
