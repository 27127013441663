import { sortSchedule } from "@sushicorp/utils";
import { weekDays } from "@sushicorp/utils";
import React, { useMemo } from "react";

import { formattingHours, getDaySchedule } from "./Schedule.helpers";
import Styles from "./Schedule.styles";
import { ScheduleProps as Props } from "./Schedule.types";

const Schedule: React.FC<Props> = props => {
  const { className, schedule = [], catalogueId, timeFormat } = props;

  // in case we get special days, we remove them here, this is because it is not
  // known which day is a "special day", so it should not be shown
  const removeSpecialDays = useMemo(
    () => schedule.filter(item => item.day !== "SPECIAL"),
    [schedule]
  );

  const scheduleToRender = useMemo(
    () =>
      sortSchedule(removeSpecialDays, catalogueId).map(item => ({
        ...item,
        day: weekDays[item.day].es,
        from: formattingHours(item.from, timeFormat),
        to: formattingHours(item.to, timeFormat)
      })),
    [removeSpecialDays, catalogueId, timeFormat]
  );

  return (
    <Styles className={`Schedule ${className}`}>
      <p className="Schedule__title">Horario</p>
      <div className="Schedule__items">
        {scheduleToRender.map((element, index) => {
          const { day, from, to } = element;
          return (
            <div
              key={index}
              className={`Schedule__item ${
                getDaySchedule(day) ? "Schedule__item--isToday" : ""
              }`}
            >
              <p className="Schedule__day">{day}</p>
              <p className="Schedule__hours">
                {from} - {to}
              </p>
            </div>
          );
        })}
      </div>
    </Styles>
  );
};

Schedule.defaultProps = {
  className: ""
};

export default Schedule;
