import { useCountries } from "@sushicorp/contexts";
import { useProductCard } from "artisn-ui-react";
import React from "react";

import Styles from "./ProductBaseInfo.styles";
import { ProductBaseInfoProps as Props } from "./ProductBaseInfo.types";
import ProductBasePriceInfo from "../ProductBasePriceInfo/ProductBasePriceInfo";

const ProductBaseInfo: React.FC<Props> = props => {
  const { product, className, largePrice } = props;
  const { decimals } = useCountries().selectedCountry;

  const { totals } = useProductCard(product, { decimals });

  return (
    <Styles className={`ProductBaseInfo ${className}`}>
      <ProductBasePriceInfo largePrice={largePrice} productTotals={totals} />
    </Styles>
  );
};

ProductBaseInfo.defaultProps = {
  className: ""
};

export default ProductBaseInfo;
