import styled, { css } from "styled-components";

import { DividerStyledProps } from "./Divider.types";
import { DividerStyledProps as Props } from "./Divider.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet, mobile } = BREAKPOINTS;

const mapHeight = (props: DividerStyledProps) => {
  const { height, heightTablet, heightMobile } = props;
  const heightDefault = height ? height : 8;

  return css`
    height: ${`${heightDefault}rem`};

    @media (max-width: ${tablet}px) {
      ${heightTablet ? `height: ${heightTablet}rem;` : ""}
    }

    @media (max-width: ${mobile}px) {
      ${heightMobile ? `height: ${heightMobile}rem;` : ""}
    }
  `;
};

const mapColor = (props: DividerStyledProps) => {
  const { color } = props;

  switch (color) {
    case "dark":
      return css`
        background-color: var(--palette-black-s0-l0);
      `;
    case "dark-light":
      return css`
        background-color: var(--palette-gray-s0-l95);
      `;
    case "gray-light":
      return css`
        background-color: var(--palette-gray-s0-l74);
      `;
    case "white":
      return css`
        background-color: var(--palette-white);
      `;
    default:
      return css`
        background-color: var(--palette-primary);
      `;
  }
};

const showDimensions = (props: DividerStyledProps) => {
  const { showDesktop = true, showTablet = true, showMobile } = props;

  return css`
    display: block;

    @media (min-width: ${tablet + 1}px) {
      display: ${!showDesktop ? "none" : "block"};
    }

    @media (max-width: ${tablet}px) {
      display: ${!showTablet ? "none" : "block"};
    }

    @media (max-width: ${mobile}px) {
      display: ${!showMobile ? "none" : "block"};
    }
  `;
};

const DividerStyled = styled.div<Props>`
  background-color: ${props =>
    props.color ? props.color : "var(--palette-gray-s0-l98)"};

  ${mapHeight}
  ${mapColor}
  ${showDimensions}

  .Divider {
  }
`;

export default DividerStyled;
