import { Modal } from "artisn-ui-react";
import styled from "styled-components";

import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { mobile } = BREAKPOINTS;

const LoadingStyled = styled(Modal)`
  .Modal__body {
    overflow: hidden !important;
    scrollbar-color: transparent, transparent;

    & > div {
      max-width: 26rem;
      max-height: 26rem;
    }

    & > .Loading__container {
      @media (max-width: ${mobile}px) {
        margin-top: 20rem;
      }
    }
  }

  .Loading {
  }
`;

export default LoadingStyled;
