import { useProducts } from "@sushicorp/contexts";
import { useFetchUser } from "@sushicorp/services";
import { ProductPreferences } from "@sushicorp/types";
import { getProductPreferences } from "@sushicorp/utils";
import React, { useState, useEffect } from "react";

import Styles from "./ModifierModal.styles";
import { ModalHeaderStyled, ModalIconStyled } from "./ModifierModal.styles";
import { ModifierModalProps as Props } from "./ModifierModal.types";
import ModifiersGroup from "../ModifiersGroup/ModifiersGroup";
import Button from "components/global/Button/Button";
import useAuth from "contexts/auth/auth.context.hooks";
import { notify } from "utils/common.utils";

import CloseSVG from "../../../../../public/assets/images/close.svg";

const ModifierModal: React.FC<Props> = props => {
  const { className, isOpen, onClose, modifierGroup } = props;
  const { name, id, modifiers, status } = modifierGroup;
  const [initialModifiers, setInitialModifiers] = useState([...modifiers]);
  const { selectedProductForm } = useProducts();
  const { validate, product } = selectedProductForm ?? {};
  const { available } = product ?? {};
  const [preferences, setPreferences] = useState<ProductPreferences>({});
  const auth = useAuth();
  const { data: user } = useFetchUser(auth, notify);
  const { uid: userUid } = user ?? {};

  const modalHeaderNode = (
    <ModalHeaderStyled id="modal-header">
      <p className="ModifierModal__header">{`Personaliza "${name}"`}</p>
    </ModalHeaderStyled>
  );

  const modalIconNode = (
    <ModalIconStyled>
      <CloseSVG />
    </ModalIconStyled>
  );

  const closeHandler = () => {
    onClose();
    modifiers.forEach(modifier => {
      const { id, handleChange, amount } = modifier;
      const initialModifier = initialModifiers.find(
        initialModifier => initialModifier.id === id
      );
      if (!initialModifier) return;
      const { amount: previousAmount } = initialModifier;
      if (amount === previousAmount) return;
      handleChange(previousAmount);
    });
  };

  const saveHandler = () => {
    if (status !== "OK" && validate) {
      validate();
      return;
    }
    setInitialModifiers([...modifiers]);
    onClose();
  };

  useEffect(() => {
    if (!product || !userUid) return;
    (async () => {
      const productPreferences = await getProductPreferences(product, userUid);
      setPreferences(productPreferences);
    })();
  }, [product, userUid]);

  return (
    <Styles
      className={`ModifierModal ${className}`}
      header={modalHeaderNode}
      closeIcon={modalIconNode}
      opened={isOpen}
      onClose={closeHandler}
    >
      <ModifiersGroup
        className="ModifierModal__modifiers-group"
        preferences={preferences}
        key={id}
        modifierGroup={modifierGroup}
        withPocket={false}
        disabled={!available}
      />
      <div className="ModifierModal__bottom-content">
        <Button className="ModifierModal__button" onClick={saveHandler}>
          Guardar
        </Button>
      </div>
    </Styles>
  );
};

ModifierModal.defaultProps = { className: "" };

export default ModifierModal;
