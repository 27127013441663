import { getMaxHeight, getMaxWidth } from "@sushicorp/utils";
import styled from "styled-components";

import { ModalHeaderStyledProps } from "./ProductModal.types";
import { ModalIconStyledProps } from "./ProductModal.types";
import { ProductModalStyledProps as Props } from "./ProductModal.types";
import Modal from "components/global/Modal/Modal";
import CONSTANTS from "config/constants";

const { tablet, mobile } = CONSTANTS.BREAKPOINTS;

const ProductModalStyled = styled(Modal)<Props>`
  border-radius: 1.2rem !important;
  width: min(${getMaxWidth()}, 96rem);
  height: min(${getMaxHeight()}, 62.4rem);
  background-color: var(--palette-white) !important;

  @media (max-width: ${tablet}px) {
    max-height: unset;
    min-width: unset;
    height: 100%;
  }

  .Modal {
    border-radius: 1.2rem !important;

    &__header {
      .ProductModal__header {
        font-weight: 400;
        font-size: 2rem;
      }
    }

    &__body {
      @media (max-width: ${tablet}px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        .ProductError {
          &__error-state {
            &__title {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .ProductModal {
    &__main {
      width: 100%;

      &--full-height {
        height: 100%;
      }
    }
  }

  && .ProductMain {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "col col";
      padding: unset;

      @media (max-width: ${tablet}px) {
        grid-template-columns: 1fr;
        grid-template-areas: "col";
        overflow: hidden;
        overflow-y: auto;
      }
    }

    &__preview-image {
      padding: 2rem;
      background-color: var(--palette-gray-s0-l98);
      border-right: 0.2rem solid var(--palette-gray-s0-l80);
      max-height: 49.6rem;

      .ProductDetailsImage {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 5.2rem;

        &__thumbnail-carousel {
          display: none;
        }

        &__zoom-image {
          &--desktop {
            display: none;
          }
        }

        &__image {
          &--tablet {
            display: none;
          }

          &--mobile {
            display: block;
          }
        }

        &__thumbnail-carousel {
          &--mobile {
            margin: 0.8rem;
            display: flex;
          }
        }
      }

      @media (max-width: ${tablet}px) {
        padding: 1.6rem;
        max-height: unset;
      }
    }

    &__form {
      overflow: hidden;
      overflow-y: auto;
      max-height: 56rem;
      background-color: var(--palette-gray-s0-l98);

      @media (max-width: ${tablet}px) {
        margin-bottom: 7.2rem;
        max-height: unset;
      }

      .Pocket {
        &__header {
          cursor: auto;

          &__title {
            &:first-child {
              font-weight: 600;
              font-size: 1.6rem;
              padding: 0.8rem 0.4rem;
            }
          }
        }

        &__icon {
          display: none;
        }
      }

      .ProductBaseInfo {
        &__name {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }
    }

    &__description {
      &__text {
        padding-left: 0.4rem;
        padding-right: 0.4rem;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    &__comment-box {
      padding-bottom: 1.2rem;
    }

    &__actions,
    &__wish-button {
      display: flex;
      position: fixed;
      width: 50%;
      bottom: 0;
      padding: 1.2rem;
      left: 0;
      height: 6.4rem;
      border-top: 0.1rem solid var(--palette-gray-s0-l20-a20);
      background-color: var(--palette-white);
      height: 6.8rem;

      .ProductMain {
        &__wishlist-button {
          margin-left: 0;
        }
      }

      @media (max-width: ${mobile}px) {
        display: none;
      }
    }

    &__actions {
      right: 0;
      left: auto;
      justify-content: flex-end;

      @media (max-width: ${tablet}px) {
        position: fixed;
        display: flex;
        justify-content: space-between;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--palette-white);
        padding: 1.2rem 1.6rem;
        align-items: center;
      }
    }

    &__addToCart {
      @media (max-width: ${tablet}px) {
        .AddToCartButton {
          &__btn {
            &__no-ok {
              width: 100%;
            }
          }
        }
      }
    }

    &__counter {
      @media (max-width: ${tablet}px) {
        margin-right: unset;
      }
    }
  }

  .ProductMain {
    &__share-button {
      &__content {
        display: flex;
        align-items: center;
      }

      &__icon {
        &-container {
          margin-right: 1.2rem;
        }
        &--mobile {
          @media (min-width: ${tablet}px) {
            display: none;
          }
        }
        &--desktop {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }
      }

      &__text {
        color: var(--palette-primary);
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }
  }

  .ProductPlaceholder {
    padding: 2.4rem;

    @media (max-width: ${tablet}px) {
      padding: 0;
    }
  }
`;

export const ModalHeaderStyled = styled.div<ModalHeaderStyledProps>`
  .ProductModal {
    &__header {
      padding: 2.4rem 3.2rem;
      font-size: 2rem;
      font-weight: 600 !important;

      @media (max-width: ${tablet}px) {
        padding: 2.4rem;
        font-size: 1.6rem;
      }
    }
  }
`;

export const ModalIconStyled = styled.div<ModalIconStyledProps>`
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${tablet}px) {
    padding: 1.2rem 0.4rem;
  }
`;

export default ProductModalStyled;
