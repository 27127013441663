import { useGlobal } from "@sushicorp/contexts";
import { useFetchUser } from "@sushicorp/services";
import { CONSTANTS } from "@sushicorp/utils";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { getAuth } from "config/artisn.config";
import useAuth from "contexts/auth/auth.context.hooks";
import { notify } from "utils/common.utils";

const { TRANSFER_ANONYMOUS_ID } = CONSTANTS.STORAGE;

const useListenUser = () => {
  const { push, asPath } = useRouter();
  const authContext = useAuth();
  const { setOpenAcceptedPrivacyDataModal } = useGlobal();
  const { isAnonymous, uid, setProviderData } = authContext;
  const { currentUser } = getAuth ?? {};
  const { data: user, error } = useFetchUser(authContext, notify);
  const { message } = error ?? {};
  // Redirect user depending on how complete is its registered process
  useEffect(() => {
    if (!isAnonymous && user) {
      const { privacyAcceptDate } = user ?? {};
      if (privacyAcceptDate === null) {
        setOpenAcceptedPrivacyDataModal(true);
      }
      const value = localStorage.getItem(TRANSFER_ANONYMOUS_ID);
      if (!value) return;
      localStorage.removeItem(TRANSFER_ANONYMOUS_ID);
      return;
    }
    if (asPath === "/signup-user" || asPath.includes("redirect")) return;
    if (message === "No user for uid" && !asPath.includes("terms")) {
      push(`/signup-user/?redirect=/`);
      return;
    }
  }, [
    message,
    push,
    isAnonymous,
    uid,
    user,
    asPath,
    setOpenAcceptedPrivacyDataModal
  ]);

  useEffect(() => {
    if (!currentUser) return;
    setProviderData(currentUser.providerData);
  }, [currentUser, setProviderData]);
};

export default useListenUser;
