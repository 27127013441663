import { useRouter } from "next/router";
import { Unmodal, useModal } from "react-unmodal";

import InfoActionModal from "../InfoActionModal/InfoActionModal";
import useI18n from "hooks/useI18n";

import FullBagSVG from "../../../../public/assets/images/fish-in-bag.svg";

const Confirm = Unmodal.create(() => {
  const modal = useModal();
  const t = useI18n();
  const { asPath } = useRouter();
  const isCheckout = asPath.includes("checkout");

  const resolve = (value: boolean | undefined) => () => {
    modal.resolve(value);
    modal.close();
  };

  return (
    <InfoActionModal
      className="InfoShoppingCartModal"
      opened={modal.isOpen}
      cancelAction={resolve(false)}
      cancelText={t.moveCart.cancel}
      confirmAction={!isCheckout ? resolve(true) : undefined}
      confirmText={!isCheckout ? t.moveCart.confirm : undefined}
      title={t.moveCart.title}
      closeOnClickOutside={true}
      description={t.moveCart.message}
      onClose={resolve(undefined)}
      icon={
        <div>
          <FullBagSVG />
        </div>
      }
    />
  );
});

export const InfoShoppingCartModal = (props: any) =>
  Unmodal.open(Confirm, props);
