import { useShoppingCart } from "@sushicorp/contexts";
import { Google } from "@sushicorp/types";
import { useCallback, useMemo } from "react";

import useBenefit from "hooks/useBenefit/useBenefit";
import useI18n from "hooks/useI18n";
import { createSuccessNotification } from "utils/notifications.utils";

const useInfoShoppingCartModal = () => {
  const { shoppingCart } = useShoppingCart();
  const { removeAppliedBenefits } = useBenefit();
  const t = useI18n();

  const keepCartHandler = useCallback(
    async (coordinates: Google.Coordinates) => {
      const [benefit] = shoppingCart?.benefits ?? [];
      try {
        await removeAppliedBenefits(benefit, coordinates);
        createSuccessNotification(t.moveCart.success);
      } catch (e) {
        console.error(e);
        throw new Error(e.message);
      }
    },
    [removeAppliedBenefits, shoppingCart?.benefits, t]
  );

  return useMemo(
    () => ({
      keepCartHandler
    }),
    [keepCartHandler]
  );
};

export default useInfoShoppingCartModal;
