import { useContext } from "react";

import { OrdersContext } from "contexts/orders/orders.context";
import { OrdersProviderValue } from "contexts/orders/orders.context.types";

const useOrders = () => {
  const context = useContext<OrdersProviderValue>(OrdersContext);
  if (typeof context === "undefined") {
    throw new Error("useOrders must be used within a AuthProvider");
  }
  return context;
};

export default useOrders;
